import { CssBaseline, ThemeProvider } from '@material-ui/core';
import type { FC } from 'react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import { LightgalleryProvider } from 'react-lightgallery';
import { useRoutes } from 'react-router-dom';
import RTL from './components/RTL';
// import SplashScreen from './components/SplashScreen';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import './i18n';
import routes from './routes';
import { createCustomTheme } from './theme';
import { isNull, isUndefined } from 'lodash';
import { deviceApi } from './api/deviceApi';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const tagManagerArgs = [{
    trackingId: process.env.REACT_APP_GA_TRACKING_ID
  }];

  useScrollReset();

  useEffect(() => {
    ReactGA.initialize(tagManagerArgs);
  }, []);

  const getGlobalConfig = async () => {
    const partnerGlobalConfig: any = await deviceApi.getGlobalConfig();
    if (!isUndefined(partnerGlobalConfig?.data)) {
      localStorage.setItem('partner_settings', JSON.stringify(partnerGlobalConfig?.data));
    }

    if (!isUndefined(partnerGlobalConfig?.distributor_name)) {
      localStorage.setItem('partner_settings', JSON.stringify(partnerGlobalConfig));
    }

    if (isNull(localStorage.getItem('partner_settings')) || isUndefined(localStorage.getItem('partner_settings'))) {
      window.location.reload();
    }
  };

  useEffect(() => {
    getGlobalConfig();
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <LightgalleryProvider
        lightgallerySettings={
                    {
                    }
                }
        galleryClassName="docs_gallery"
      >
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster position="top-center" />
          {/* {auth.isInitialized ? content : <SplashScreen />} */}
          { content }
        </RTL>
      </LightgalleryProvider>
    </ThemeProvider>
  );
};

export default App;
