import axios from 'axios';
import i18next from 'i18next';
import { isUndefined } from 'lodash';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

class DeviceApi {
  async getDocumentsByArticleNumber(articleNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_DOCS_URL}/article/${articleNumber}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDocumentsByGroupName(groupName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_DOCS_URL}/groupname/${groupName}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getDocumentsDirectories(): Promise <any> {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_DOCS_URL}/all`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllErrorDocs(search: string, sortColumn: any, sortDirection: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get All Error Docs
      let sortList = {};

      if (sortColumn.groupName) {
        sortList = {
          groupName: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.files) {
        sortList = {
          files: sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        sortList
      };

      let data: any;

      if (Object.keys(sortList)?.length === 0) {
        const sortListProp = 'sortList';
        delete filter[sortListProp];
      }

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search
        };
      } else {
        data = {
          Filter: filter,
        };
      }

      axios.post(`${process.env.REACT_APP_DOCS_URL}/allIncorrectFiles`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getGlobalConfig(): Promise<any> {
    return new Promise((resolve, reject) => {
      // get Global Config
      axios.get(`${process.env.REACT_APP_USERS_URL?.replace('v1/user', 'v1')}/global-config`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }
}

export const deviceApi = new DeviceApi();
