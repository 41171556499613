import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './locale/en';
import { nl } from './locale/nl';
import { de } from './locale/de';
import { dk } from './locale/dk';
import { fr } from './locale/fr';
// import { en, nl, de, dk, fr } from './locale';

const resources = {
  en: {
    en
  },
  nl: {
    nl
  },
  de: {
    de
  },
  dk: {
    dk
  },
  fr: {
    fr
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'nl' || 'nl',
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false
    }
  });
