export const dk = {
  login_sign_in: 'Login',
  login_email_address: 'Email adresse',
  login_form_password_label: 'Adgangskode',
  login_forget_password: 'Glemt adgangskode',
  login_credentials_error: 'Adgangskode eller email er forkert',
  login_api_error: 'Internal login API Alarm',
  internal_server_error: 'Intern serveralarm',
  /* Forget Password */
  forget_password_title: 'Glemt adgangskode',
  forget_password_email_address: 'Email adresse',
  forget_password_button: 'Nulstil adgangskode',
  forget_password_login: 'Retur',
  forgot_password_form_success:
           'Din adgangskode er nulstillet. Tjek venligst din email. Mailen kan ende i spam.',
  forgot_password_subject: 'Fortes Data Management  - Glemt adgangskode',
  temporary_password_subject:
           'Fortes Data Management – Ny konto oprettet',
  forgot_password_required_form_error: 'Email er påkrævet',
  forgot_password_valid_form_error: 'Skal være en gyldig email',
  /* END Forget Password */
  /** Reset password */
  reset_password_title: 'Nulstil adgangskode',
  reset_password_new_password: 'Nyt adgangskode',
  reset_password_confirm_password: 'Bekræft adgangskode',
  reset_password_new_password_required_form_error:
           'Ny adgangskode er påkrævet',
  reset_password_confirm_password_required_form_error:
           'Adgangskode er påkrævet',
  reset_password_passwords_not_matching: 'Adgangskoden er ikke korrekt',
  reset_password_new_password_form_error:
           'Din adgangskode skal indeholde 8 tegn, mindst et stort bogstav, et tal og et specialtegn.',
  reset_password_button: 'Nulstil adgangskode',
  reset_password_form_success: 'Din adgangskode er opdateret!',
  /** End reset password */
  /* DASHBOARD */
  dashboard_title: 'Dashboard',
  dashboard_amount_of_devices: 'ANTAL ENHEDER',
  dashboard_amount_of_devices_week: 'ANTAL ENHEDER MED FEJL',
  dashboard_temperature: 'RETUR TEMPERATUR I GRADER',
  dashboard_device_status: 'Forbindelser i alt: ',
  dashboard_routing_temperature: 'Temperatur på alle enheder',
  dashboard_routing_temperature_hr: 'Hr',
  dashboard_error_devices: 'Enheder med defekt styring:',
  dashboard_error: 'FEJL',
  dashboard_online: 'ONLINE',
  dashboard_offline: 'OFFLINE',
  'dashboard organic': 'Organisk',
  'dashboard referal': 'Henvisning',
  dashboard_see_all: 'SE ALLE',
  /* END DASHBOARD */
  /* Sidebar */
  date_range: 'Dato interval',
  sidebar_dashboard: 'Dashboard',
  sidebar_clients_overview: 'Kundehåndtering',
  sidebar_clients: 'Kunder',
  sidebar_service_clients: 'Service kunder',
  sidebar_devices_overview: 'Enhedshåndtering',
  sidebar_devices: 'Enhedsoversigt',
  sidebar_projects: 'Ordrenummer',
  sidebar_presets: 'Presets',
  sidebar_fortes_users: 'Administrator',
  sidebar_fortes_settings: 'Indstillinger',
  sidebar_audit: 'Audit',
  sidebar_reports: 'Rapporter',
  sidebar_total_devices: 'Enheder - Total',
  sidebar_online_devices: 'Enheder - Online',
  sidebar_offline_devices: 'Enheder - Offline',
  sidebar_error_devices: 'Enheder - Fejl',
  sidebar_change_logs: 'Fejlkoder',
  sidebar_version_title: 'Version',
  image_upload_success: 'Dit billede er uploadet!',
  image_upload_file_type_error: 'Kun JPEG og PNG filer kan bruges',
  image_upload_file_size_error: 'Filen må ikke fylde over 5 MB.',
  image_uploading_text: 'Gemmer...',
  /* END Sidebar */
  /* Navbar */
  navbar_search: 'Søg',
  navbar_notification: 'Notifikation',
  navbar_no_notification: 'Der er ingen notifikationer',
  navbar_profile: 'Profil',
  navbar_setting: 'Kontoindstillinger',
  navbar_logout: 'Log ud',
  /* END Navbar */
  active_label: 'AKTIVE',
  deactive_label: 'INAKTIVE',
  please_fix_form_errors: 'Ret formularfejl',
  /* Clients */
  clients: 'Kunder',
  clients_all: 'ALLE',
  clients_active: 'AKTIVE',
  clients_inactive: 'INAKTIVE',
  clients_add_new: 'Tilføj ny kunde',
  clients_filter_search: 'Søg i kunder',
  clients_sort_by_filter_label: 'Sorter efter',
  clients_sort_by_value_1: 'Dato(nyeste)',
  clients_sort_by_value_2: 'Dato(ældste)',
  clients_sort_by_value_3: 'Alfabetet',
  clients_country_filter_label: 'Land',
  clients_filter_reset_button: 'Nulstil',
  clients_filter_delete_button: 'Deaktiver',
  clients_table_name: 'Navn',
  clients_table_country: 'Land',
  clients_table_devices: 'Enheder',
  clients_table_added: 'Oprettelsesdato',
  clients_table_status: 'Status',
  clients_table_row_per_page: 'Rækker pr.side',
  clients_bread_crumb_main_title: 'Kunder',
  add_clients_bread_crumb_first_link: 'Tilføj kunde',
  add_clients_form_header: 'Tilføj kunde',
  edit_clients_bread_crumb_first_link: 'Redigér kunde',
  edit_clients_form_header: 'Redigér kunde',
  clients_form_client_name_field: 'Navn',
  clients_form_email_field: 'Email adresse',
  clients_form_user_field: 'Bruger',
  clients_form_city_field: 'By',
  clients_form_country_field: 'Land',
  clients_form_client_3_scale_role_field: '3Scale Role',
  clients_form_client_3_scale_role_member_label: 'Medlemmer',
  clients_form_client_3_scale_role_admin_label: 'Administrator',
  clients_form_allow_customization_field: 'Allow white-labeling',
  clients_form_add_client_button: 'Tilføj kunde',
  clients_form_edit_client_button: 'Gem ændringer',
  clients_form_cancel_button: 'Annuller',
  clients_form_submitting_client_button: 'Gemmer...',
  clients_name_required_form_error: 'Navn er påkrævet',
  clients_email_required_form_error: 'Email adresse er påkrævet',
  clients_api_3scale_role_required_form_error: '3Scale Role er påkrævet',
  clients_email_valid_form_error: 'Skal være en gyldig email adresse',
  clients_city_required_form_error: 'By er påkrævet',
  clients_country_required_form_error: 'Land er påkrævet',
  clients_add_form_success: 'Kunden er oprettet!',
  clients_edit_form_success: 'Kunden er opdateret!',
  clients_form_api_error: 'Internal Client - API Server Alarm',
  clients_form_email_error: 'Emailen eksistere allerede',
  clients_no_record_found: 'Ingen kunder fundet',
  client_deactivated_message: 'Kunden er deaktiveret',
  client_activated_message: 'Kunden er aktiveret',
  clients_bulk_no_user_is_selected_error_message:
           'Vælg kunder for at deaktivere',
  clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} Kunderne er deaktiveret',
  client_single_stats_table_title: 'Status',
  /* END Clients */
  client_single_first_bread_crumb: 'Kunder',
  client_single_second_bread_crumb: 'Se kunder',
  client_single_country_label: 'Land',
  client_single_devices_label: 'Enheder',
  client_single_edit_button: 'Redigér',
  client_single_users_tab: 'Bruger',
  client_single_apis_tab: 'API nøgler',
  client_single_customization_tab: 'White label',
  client_single_customization_tab_logo: 'Logo',
  client_single_customization_tab_primary_color: 'Primær farve',
  client_single_customization_tab_secondary_color: 'Sekundær farve',
  client_single_customization_tab_submit_button: 'Gem ændringer',
  client_single_customization_color_schema_success:
           'White-label er opdateret.',
  client_single_filter_search: 'Søg',
  client_single_add_new_user_button: 'Tilføj bruger',
  client_single_add_new_api_key_button: 'Tilføj API nøgle',
  client_single_role_id_filter: 'Rolle',
  client_single_users_search: 'Søg',
  client_single_filter_reset_button: 'Nulstil',
  client_single_users_all: 'ALLE',
  client_single_users_active: 'AKTIVE',
  client_single_users_inactive: 'INAKTIVE',
  client_single_filter_delete_button: 'Deaktiveret',
  client_single_users_table_name: 'Navn',
  client_single_users_table_address: 'Adresse',
  client_single_users_table_role_id: 'Rolle',
  client_single_users_table_status: 'Status',
  client_single_users_table_added: 'Oprettelsesdato',
  client_single_users_table_no_record_found: 'Ingen søgniger fundet',
  client_single_table_row_per_page: 'Rækker pr.side',
  client_single_stats_online_devices_label: 'Online enheder',
  client_single_stats_error_devices_label: 'Alarm enheder',
  client_single_no_user_selected_for_de_activation_message:
           'Vælg enhed som skal deaktiveres',
  /* END Clients Single */
  /* Service enheder */
  service_clients: 'Service kunder',
  service_clients_all: 'ALLE',
  service_clients_active: 'AKTIVE',
  service_clients_inactive: 'INAKTIVE',
  service_clients_add_new: 'Tilføj ny',
  service_clients_filter_search: 'Søg',
  service_clients_sort_by_filter_label: 'Sorter efter',
  service_clients_sort_by_value_1: 'Dato(nyeste)',
  service_clients_sort_by_value_2: 'Dato(ældste)',
  service_clients_sort_by_value_3: 'Alfabetet',
  service_clients_country_filter_label: 'Land',
  service_clients_filter_reset_button: 'Nulstil',
  service_clients_filter_delete_button: 'Deaktiver',
  service_clients_table_name: 'Navn',
  service_clients_table_country: 'Land',
  service_clients_table_devices: 'Enheder',
  service_clients_table_added: 'Oprettelsesdato',
  service_clients_table_status: 'Status',
  service_clients_table_row_per_page: 'Rækker pr.side',
  service_clients_bread_crumb_main_title: 'Service enheder',
  add_service_clients_bread_crumb_first_link: 'Tilføj service enhed',
  add_service_clients_form_header: 'Tilføj service enhed',
  edit_service_clients_bread_crumb_first_link: 'Redigér service enhed',
  edit_service_clients_form_header: 'Redigér service enhed',
  service_clients_form_service_client_name_field: 'Navn',
  service_clients_form_email_field: 'Email adresse',
  service_clients_form_client_field: 'Kunde',
  service_clients_form_user_field: 'Bruger',
  service_clients_form_city_field: 'By',
  service_clients_form_country_field: 'Land',
  service_clients_form_add_service_client_button: 'Gem ændringer',
  service_clients_form_edit_service_client_button: 'Gem ændringer',
  service_clients_form_cancel_button: 'Annuller',
  service_clients_form_submitting_service_client_button: 'Gemmer...',
  service_clients_name_required_form_error: 'Navn er påkrævet',
  service_clients_email_required_form_error: 'Email er påkrævet',
  service_clients_focal_user_id_required_form_error:
           'Focal User is required',
  service_clients_email_valid_form_error:
           'Skal være en gyldig email adresse',
  service_clients_city_required_form_error: 'By er påkrævet',
  service_clients_client_required_form_error: 'Kunde er påkrævet',
  service_clients_country_required_form_error: 'Land er påkrævet',
  service_clients_add_form_success: 'Service enhed er oprettet!',
  service_clients_edit_form_success: 'Service enhed er opdateret!',
  service_clients_form_api_error:
           'Internal Service kunde - API Serveralarm',
  service_clients_form_email_error: 'Emailen eksistere allerede',
  service_clients_no_record_found: 'Ingen service enheder fundet',
  service_client_deactivated_message: 'Service enheden er deaktiveret',
  service_client_activated_message: 'Service enheden er aktiveret',
  service_clients_bulk_no_user_is_selected_error_message:
           'Vælg enhed som skal deaktiveres',
  service_clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} Service kunde(r) er deaktiveret',
  service_client_single_stats_table_title: 'Status',
  /* END Service enheder */
  service_client_single_first_bread_crumb: 'Service enheder',
  service_client_single_second_bread_crumb: 'Se service enheder',
  service_client_single_country_label: 'Land',
  service_client_single_devices_label: 'Enheder',
  service_client_single_edit_button: 'Redigér',
  service_client_single_users_tab: 'Bruger',
  service_client_single_apis_tab: 'API nøgler',
  service_client_single_filter_search: 'Sød',
  service_client_single_add_new_user_button: 'Tilføj bruger',
  service_client_single_add_new_api_key_button: 'Tilføj API nøgler',
  service_client_single_role_id_filter: 'Rolle',
  service_client_single_users_search: 'Søg',
  service_client_single_filter_reset_button: 'Nulstil',
  service_client_single_users_all: 'ALLE',
  service_client_single_users_active: 'AKTIVE',
  service_client_single_users_inactive: 'INAKTIVE',
  service_client_single_filter_delete_button: 'Deaktiver',
  service_client_single_users_table_name: 'Navn',
  service_client_single_users_table_address: 'Adresse',
  service_client_single_users_table_role_id: 'Rolle',
  service_client_single_users_table_status: 'Status',
  service_client_single_users_table_added: 'Oprettelsesdato',
  service_client_single_users_table_no_record_found:
           'Ingen enheder fundet',
  service_client_single_table_row_per_page: 'Rækker pr.side',
  service_client_single_stats_active_devices_label: 'Online enheder',
  service_client_single_stats_error_devices_label: 'Alarm enheder',
  service_client_single_no_user_selected_for_de_activation_message:
           'Vælg enhed som skal deaktiveres',
  /* END Service kundes Single */
  /* Users */
  users_bread_crumb_main_title: 'Kunde',
  add_users_bread_crumb_first_link: 'Tilføj bruger',
  add_users_form_header: 'Tilføj bruger',
  edit_users_bread_crumb_first_link: 'Redigér bruger',
  edit_users_form_header: 'Redigér bruger',
  users_form_first_name_field: 'Fornavn',
  users_form_last_name_field: 'Efternavn',
  users_form_email_field: 'Email adresse',
  users_form_official_email_field: 'Offical email adresse',
  users_form_phone_field: 'Telefonnummer',
  users_form_official_phone_number_field: 'Offical telefonnummer',
  users_form_password_field: 'Adgangskode',
  users_form_role_id_field: 'Rolle',
  users_form_select_role_id_field: 'Vælg rolle',
  users_form_street_number_field: 'Gadenavn',
  users_form_house_number_field: 'Husnummer',
  users_form_neighbourhood_field: 'Område',
  users_form_postal_code_field: 'Postnummer',
  users_form_city_field: 'By',
  users_form_country_field: 'Land',
  users_form_email_verification_field: 'Verificer din email',
  users_form_email_verification_sub_title_field:
           'Send automatisk en verificerings email',
  users_form_add_user_button: 'Gem ændringer',
  users_form_edit_user_button: 'Gem ændringer',
  users_form_cancel_button: 'Annuller',
  users_form_submitting_user_button: 'Gemmer...',
  users_first_name_required_form_error: 'Fornavn er påkrævet',
  users_last_name_required_form_error: 'Efternavn er påkrævet',
  users_email_required_form_error: 'Email er påkrævet',
  users_email_valid_form_error: 'Skal være en gyldig email adresse',
  users_password_required_form_error: 'Adgangskode er påkrævet',
  users_country_required_form_error: 'Land er påkrævet',
  users_role_id_required_form_error: 'Rolle er påkrævet',
  users_password_form_error:
           'Din adgangskode skal indeholde 8 tegn, mindst et stort bogstav, et tal og et specialtegn.',
  users_add_form_success: 'Brugeren er oprettet!',
  users_edit_form_success: 'Brugeren er opdateret!',
  users_form_api_error: 'Internal Users - API Serveralarm',
  users_form_email_error: 'Email adressen eksistere allerede',
  users_no_record_found: 'Ingen bruger fundet',
  user_deactivated_message: 'Brugeren er deaktiveret',
  user_activated_message: 'Brugeren er aktiveret',
  user_email_verified_title: 'Email adressen er verificeret',
  user_email_verified_sub_title:
           'Send automatisk en verificerings mail til brugeren',
  users_bulk_no_user_is_selected_error_message:
           'Vælg enhed som skal deaktiveres',
  users_bulk_deactivated_message:
           '{{ USERS_COUNT }} Brugerne er deaktiveret',
  /** Projects */
  projects: 'Projekter',
  projects_all: 'ALLE',
  projects_active: 'AKTIVE',
  projects_inactive: 'INAKTIVE',
  projects_unassigned: 'IKKE TILDELT',
  projects_add_new: 'Tilføj ny',
  projects_filter_search: 'Søg projekt',
  projects_sort_by_filter_label: 'Sorter efter',
  projects_sort_by_value_1: 'Dato(nyeste)',
  projects_sort_by_value_2: 'Dato(ældste)',
  projects_sort_by_value_3: 'Alfabetet',
  projects_country_filter_label: 'Land',
  projects_filter_reset_button: 'Nulstil',
  projects_filter_delete_button: 'Slet',
  projects_table_name: 'Navn',
  projects_table_batch_number: 'Batch nummer',
  projects_table_client: 'Kunder',
  projects_table_devices: 'Enheder',
  projects_table_added: 'Oprettelsesdato',
  projects_table_status: 'Status',
  projects_table_row_per_page: 'Rækker pr.side',
  projects_bread_crumb_main_title: 'Projekt',
  add_projects_bread_crumb_first_link: 'Tilføj projekt',
  add_projects_form_header: 'Tilføj projekt',
  edit_projects_bread_crumb_first_link: 'Redigér projekt',
  edit_projects_form_header: 'Redigér projekt',
  projects_form_project_name_field: 'Projekt navn',
  projects_form_email_field: 'Email adresse',
  projects_form_client_field: 'Kunder',
  projects_form_service_client_field: 'Service kunde',
  projects_form_user_field: 'Bruger',
  projects_form_description_field: 'Beskrivelse (valgfrit)',
  projects_form_add_project_button: 'Gem ændringer',
  projects_form_edit_project_button: 'Gem ændringer',
  projects_form_cancel_button: 'Annuller',
  projects_form_submitting_service_client_button: 'Gemmer...',
  projects_name_required_form_error: 'Navn er påkrævet',
  projects_form_unqiue_project_order_number_error:
           'Projektnummeret skal være unikt',
  projects_batch_number_required_form_error: 'Batch nummer er påkrævet',
  projects_client_required_form_error: 'Kunde er påkrævet',
  projects_description_required_form_error: 'Beskrivelse er påkrævet',
  projects_add_form_success: 'Projektet er oprettet!',
  projects_edit_form_success: 'Projektet er opdateret!',
  projects_form_api_error: 'Internal projects - API Server Alarm',
  projects_no_record_found: 'Ingen projekter fundet',
  projects_deactivated_message: 'Projektet er deaktiveret',
  projects_activated_message: 'Projektet er aktiveret',
  projects_bulk_no_user_is_selected_error_message:
           'Vælg enhed som skal deaktiveres',
  projects_bulk_deactivated_message:
           '{{ USERS_COUNT }} Projekterne er deaktiveret',
  projects_single_stats_table_title: 'Status',
  /** Devices */
  devices: 'Enheder',
  devices_all: 'Alle',
  devices_active: 'Online',
  devices_inactive: 'Offline',
  devices_error: 'FEJL',
  devices_register_enabled: 'Aktiveret',
  devices_register_disabled: 'Deaktiveret',
  devices_add_new: 'Tilføj ny',
  devices_name: 'Navn',
  devices_address: 'Installations adresse',
  devices_client: 'Kunder',
  devices_project: 'Projekt',
  devices_status: 'Status',
  devices_description: 'Fjernvarmeunit',
  device_is_online: 'Enheden er "online"',
  device_is_offline: 'Enheden er "offline"',
  devices_last_viewed: 'Sidst set',
  devices_action: 'Søg',
  devices_row_per_page: 'Rækker pr.side',
  device_rssi_title: 'RSSI',
  device_status_reg_105_no_error_255: 'Ingen fejl',
  device_status_menu_list: JSON.stringify([
    {
      key: 'operating_state',
      value: 'Oversigt',
      permission: 'operating-state-tab-read',
    },
    {
      key: 'settings_hot_water',
      value: 'Indstillinger varmt vand',
      permission: 'settings-hot-water-tab-read',
    },
    {
      key: 'settings_heating_and_cooling',
      value: 'Indstillinger varme',
      permission: 'settings-heating-and-cooling-tab-read',
    },
    {
      key: 'manual_control',
      value: 'Manuel styring',
      permission: 'manual-control-tab-read',
    },
    {
      key: 'm_bus_meters',
      value: 'M-Bus målere',
      permission: 'm-bus-meters-tab-read',
    },
    {
      key: 'live_hot_water',
      value: 'Livelog varmt vand',
      permission: 'livelog-hot-water-tab-read',
    },
    {
      key: 'live_heating',
      value: 'Livelog varme',
      permission: 'livelog-heating-tab-read',
    },
    {
      key: 'live_cooling',
      value: 'Livelog køling',
      permission: 'livelog-cooling-tab-read',
    },
  ]),
  device_status_menu_keys_list: JSON.stringify([
    'operating_state',
    'settings_hot_water',
    'settings_heating_and_cooling',
    'manual_control',
    'm_bus_meters',
    'live_hot_water',
    'live_heating',
    'live_cooling',
  ]),
  devices_filter_search: 'Søg',
  devices_sort_by_filter_label: 'Sorter efter',
  devices_sort_by_value_1: 'Dato(nyeste)',
  devices_sort_by_value_2: 'Dato(ældste)',
  devices_sort_by_value_3: 'Alfabetet',
  devices_country_filter_label: 'Land',
  devices_filter_reset_button: 'Nulstil',
  devices_filter_delete_button: 'Slet',
  devices_table_name: 'Navn',
  devices_table_firmware: 'Firmware',
  devices_table_device_type: 'Enhedstype',
  devices_table_batch_number: 'Batch nummer',
  devices_table_client: 'Kunder',
  devices_table_devices: 'Enheder',
  devices_table_added: 'Oprettelsesdato',
  devices_table_status: 'Status',
  devices_table_row_per_page: 'Rækker pr.side',
  devices_bread_crumb_main_title: 'Enheder',
  add_devices_bread_crumb_first_link: 'Tilføj enhed',
  add_devices_form_header: 'Tilføj enhed',
  edit_devices_bread_crumb_first_link: 'Redigér enhed',
  edit_devices_form_header: 'Redigér enhed',
  devices_form_device_information_tab: 'Enhedsinformation',
  devices_form_name_field: 'Enhedsnavn',
  devices_form_type_field: 'Enhedstype',
  devices_form_ip_address_field: 'IP adresse',
  devices_form_preset_field: 'Preset',
  devices_form_project_field: 'Projekt',
  devices_form_client_field: 'Kunde',
  devices_form_batch_number_field: 'Batch nummer',
  devices_form_serial_number_field: 'Mercurius serienummer',
  devices_form_box_serial_number_field: 'Serienummer fjernvarmeunit',
  devices_form_firmware_field: 'Firmware version',
  devices_form_description_field: 'Fjernvarmeunit',
  devices_form_device_address_tab: 'Installations adresse',
  devices_form_street_number_field: 'Gade',
  devices_form_house_number_field: 'Husnummer',
  devices_form_postal_code_field: 'Postnummer',
  devices_form_neighbourhood_field: 'Yderligere information',
  devices_form_city_field: 'By',
  devices_form_country_field: 'Land',
  devices_form_floor_number_field: 'Etage',
  devices_form_building_number_field: 'Bygning',
  devices_form_additional_information_tab: 'Yderligere information',
  devices_form_sn_heat_unit_field: 'Serienummer fjernvarmeunit',
  devices_form_article_number_field: 'Varenummer fjernvarmeunit',
  devices_form_prod_order_nr_week_field: 'Produktions ordrenummer',
  devices_form_serial_number_mercurius_field: 'Mercurius serienummer',
  devices_form_sim_card_number_field: 'Simkort nummer',
  devices_form_customer_ref_field: 'Kunde reference',
  devices_form_production_date_field: 'Produktionsdato',
  devices_form_order_number_field: 'Ordrer nummer',
  devices_form_product_number_field: 'Artikel nummer',
  devices_form_model_number_field: 'Model nummer',
  devices_form_note_field: 'Noter (valgfrit)',
  devices_form_settings_tab: 'Indstillinger',
  devices_form_device_interval_field: 'Interval',
  devices_form_is_device_enabled_sensor_field: 'Enable sensor data',
  devices_form_is_device_activated_field: 'Deaktiver enhed',
  devices_form_mbus_tab: 'M-Bus',
  devices_form_is_device_enabled_heat_meter_field: 'Enable heat meter',
  devices_form_is_device_enabled_cool_meter_field: 'Enable cool meter',
  devices_form_is_device_enabled_electric_meter_field:
           'Enable electricity meter',
  devices_form_is_device_enabled_bi_directional_meter_field:
           'Enable heat/cool meter',
  devices_form_is_device_enabled_water_meter_field: 'Enable water meter',
  devices_form_is_device_enabled_mbus_search_meter_button: 'M-Bus søg',
  devices_form_add_device_button: 'Tilføj enhed',
  devices_form_edit_device_button: 'Gem ændringer',
  devices_form_cancel_button: 'Annuller',
  devices_form_submitting_service_client_button: 'Gemmer...',
  devices_name_required_form_error: 'Navn er påkrævet',
  devices_batch_number_required_form_error: 'Batch nummer er påkrævet',
  devices_serial_number_required_form_error: 'Serienummer er påkrævet',
  devices_sn_heat_number_numeric_form_error:
           'Serienummer fjernvarmeunit skal være et tal',
  devices_article_number_form_error: 'Artikelnummer er påkrævet',
  devices_prod_order_nr_week_form_error:
           'Produktions ordrenummer er påkrævet',
  devices_serial_number_mercurius_form_error:
           'Mercurius serienummer er påkrævet',
  devices_sim_card_number_form_error: 'Simkort nummer er påkrævet',
  devices_device_interval_numeric_form_error:
           'Enhedsinterval skal være et tal',
  devices_client_required_form_error: 'Kunde er påkrævet',
  devices_project_required_form_error: 'Projekt er påkrævet',
  devices_preset_required_form_error: 'Preset er påkrævet',
  devices_description_required_form_error: 'Beskrivelse er påkrævet',
  devices_add_form_success: 'Enheden er oprettet!',
  devices_edit_form_success: 'Enheden er opdateret!',
  devices_form_unique_error:
           'Serienummer fjernvarmeunit and Mercurius serienummer skal være unikke',
  devices_form_api_error: 'Internal devices - API Serveralarm',
  devices_no_record_found: 'Ingen enheder fundet',
  devices_deactivated_message: 'Enheden er deaktiveret',
  devices_activated_message: 'Enheden er aktiveret',
  devices_search_mbus_registers_success:
           'Beskeden er sendt til enheden.',
  devices_search_mbus_registers_missing_serial_number_error:
           'Skriv venligst serienummer for at forsætte',
  devices_bulk_no_user_is_selected_error_message:
           'Vælg enhed som skal deaktiveres',
  devices_bulk_deactivated_message:
           '{{ USERS_COUNT }} Enhederne er deaktiveret',
  // Devices Single
  device_single_page_title: 'Enhedsdetaljer',
  devices_single_bread_crumb_main_title: 'Enhedsoversigt',
  devices_single_bread_crumb_first_link: 'Enhedsside',
  devices_single_basic_details_client: 'KUNDE',
  devices_single_basic_details_serial_number: 'Mercurius serienummer',
  devices_single_basic_details_firmware: 'FIRMWARE',
  devices_single_basic_details_type: 'Enhedstype',
  devices_single_edit_device_button: 'Redigér enhed',
  device_single_error_status: 'Fejl',
  device_single_online_status: 'Online',
  device_single_offline_status: 'Offline',
  device_single_rss_device_status: 'Enhedsstatus',
  device_single_rss_signal_strength: 'Signalstyrke',
  device_single_temperature_tab: 'Temperatur graf',
  device_single_temperature_title: 'Historisk data om fjernvarmeunit',
  device_single_temperature_hr: 'Timer',
  device_single_registers_tab: 'Oversigt',
  device_single_event_tab: 'Events',
  device_single_device_details_tab: 'Info',
  device_single_device_api_timer_seconds_label: 'Sekunder',
  device_single_device_api_timer_minute_label: 'Minut',
  device_single_device_api_timer_minutes_label: 'Minutter',
  device_single_recall_device_status_api_timer_field: 'API Interval',
  devices_single_last_seen: 'Sidst set',
  devices_single_fetch_registers: 'DATAINDSAMLING',
  fetch_registers_success: 'Anmodningen er sendt til enheden.',
  write_registers_success: 'Forespørgsel er sendt til enheden.',
  write_registers_error: 'Venligst ændre værdien for at forsætte.',
  fetch_registers_m_bus_meters_not_supported:
           'M-Bus målere er ikke understøttet i øjeblikket.',
  // Operating Status
  business_situation_operating_status_title: 'Oversigt fjernvarmeunit',
  device_status_nr: 'NR',
  device_status_name: 'Navn',
  device_status_value: 'Værdi',
  business_situation_operating_status_100: 'Varme status',
  business_situation_operating_status_100_list: JSON.stringify({
    list: {
      0: 'Tomgang',
      1: 'Anmod',
      2: 'On',
      3: 'Pumpe efterløb',
      4: 'Off',
      5: 'Pumpe opstart',
    },
  }),
  business_situation_operating_status_101: 'Varmt vand status',
  business_situation_operating_status_101_list: JSON.stringify({
    list: {
      0: 'Tomgang',
      1: 'Anmod',
      2: 'On',
      3: 'Varmt vand efterløb',
      4: 'Off',
    },
  }),
  business_situation_operating_status_102: 'Tomgang status',
  business_situation_operating_status_102_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'On',
    },
  }),
  business_situation_operating_status_103: 'Termisk desinfektion status',
  business_situation_operating_status_103_list: JSON.stringify({
    list: {
      0: 'Tomgang',
      1: 'Anmod',
      2: 'On',
      3: 'Pumpe efterløb',
      4: 'Off',
    },
  }),
  business_situation_operating_status_104: 'Køling status',
  business_situation_operating_status_104_list: JSON.stringify({
    list: {
      0: 'Tomgang',
      1: 'On',
      2: 'Init',
      3: 'Demand',
      4: 'Pumpe efterløb',
      5: 'Pumpe opstart',
    },
  }),
  business_situation_operating_status_1802: 'TE Heating Element State',
  business_situation_operating_status_1802_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'On',
    },
  }),
  business_situation_operating_status_105: 'Alarm kode',
  business_situation_operating_status_106: 'Software version',
  business_situation_operating_status_107: 'Ventil (M1)',
  business_situation_operating_status_108: 'Ventil (M2)',
  business_situation_operating_status_109: 'Pumpe',
  business_situation_operating_status_109_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'On',
    },
  }),
  business_situation_operating_status_110: 'Ikke i brug',
  business_situation_operating_status_110_list: JSON.stringify({
    list: {
      0: 'Ikke i brug',
      1: 'Ikke i brug',
    },
  }),
  business_situation_operating_status_112_list: JSON.stringify({
    list: {
      0: 'Heating or demand',
      1: 'Cooling',
    },
  }),
  business_situation_operating_status_113: 'Rum termostat',
  business_situation_operating_status_113_list: JSON.stringify({
    list: {
      1: 'Ingen varme krav',
      2: 'Varme krav',
    },
  }),
  business_situation_operating_status_114: 'OpenTherm Indicator',
  business_situation_operating_status_114_list: JSON.stringify({
    list: {
      0: 'Inaktiv',
      1: 'Aktiv',
    },
  }),
  business_situation_operating_status_115: 'Appliance Type',
  // Business situation
  business_situation_measured_values_title: 'Akutel status/målinger',
  business_situation_measured_values_200: 'Varmt vands flow',
  business_situation_measured_values_201: 'Varmt vands temperatur',
  business_situation_measured_values_202: 'Fjernvarme fremløb',
  business_situation_measured_values_203: 'Udendørstemperatur',
  business_situation_measured_values_205: 'Varme fremløb',
  business_situation_measured_values_206: 'Fjernvarme retur',
  business_situation_measured_values_204: 'Fremløb setpunkt',
  business_situation_measured_values_207: 'Rum temperatur (OpenTherm)',
  business_situation_measured_values_208: 'Rum setpoint (OpenTherm)',
  business_situation_measured_values_210: 'Sekundær vandtryk',
  // Operating time
  business_situation_operating_time_title: 'Drifttid',
  business_situation_operating_time_800: 'Total drifttid',
  business_situation_operating_time_801: 'Varme drifttid',
  business_situation_operating_time_802: 'Køle drifttid',
  business_situation_operating_time_803: 'Varmt vand drifttid',
  business_situation_operating_time_804: 'Antal tap (varmt vand)',
  business_situation_operating_time_805: 'Hot water Volume',
  business_situation_operating_time_806: 'Power cuts',
  // Warmtapwater
  settings_dhw_warm_tap_water_title: 'Indstillinger varmt vand',
  settings_dhw_warm_tap_water_300: 'Varmt vand setpoint',
  settings_dhw_warm_tap_water_301: 'Standby setpunkt',
  settings_dhw_warm_tap_water_301_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'Frostsikring',
      2: 'Eco',
      3: 'Komfort',
      4: 'Dynamisk',
    },
  }),
  settings_dhw_warm_tap_water_302: 'Varmt vand standby mode',
  settings_dhw_warm_tap_water_303: 'Minimum flow',
  settings_dhw_warm_tap_water_304: 'Varmt vand',
  settings_dhw_warm_tap_water_304_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'On',
    },
  }),
  // Termisk desinfektion
  settings_thermal_disinfection_title:
           'Indstillinger termisk desinfektion',
  settings_thermal_disinfection_400: 'Termisk desinfektion',
  settings_thermal_disinfection_400_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'On',
    },
  }),
  settings_thermal_disinfection_401: 'Termisk desinfektion setpoint',
  settings_thermal_disinfection_402: 'Termisk desinfektion drifttid',
  settings_thermal_disinfection_403: 'Termisk desinfektion ventetid',
  // TE-Booster
  settings_dhw_te_booster_title: 'Ikke i brug',
  settings_dhw_te_booster_1800: 'TE-Booster',
  settings_dhw_te_booster_1800_list: JSON.stringify({
    list: {
      0: 'Disabled',
      1: 'Enabled',
    },
  }),
  settings_dhw_te_booster_1804: 'TE-Booster setpoint',
  settings_dhw_te_booster_1805: 'TE-Booster setpoint hysteresis',
  settings_dhw_te_booster_1812: 'TE-Booster setpoint timeout',
  settings_dhw_te_booster_1813: 'TE-Booster setpoint timer',
  settings_dhw_te_booster_1815: 'TE-Booster threshold',
  settings_dhw_te_booster_1816: 'TE-Booster taped volume',
  // Heating
  heating_and_cooling_settings_heating_title: 'Indstillinger varme',
  heating_and_cooling_settings_heating_500: 'Fremløb setpunkt max.',
  heating_and_cooling_settings_heating_501: 'Fremløb setpunkt min.',
  heating_and_cooling_settings_heating_506: 'Retur setpunkt max.',
  heating_and_cooling_settings_heating_507: 'Retur kompensering',
  heating_and_cooling_settings_heating_508: 'Offset fremløb pri/sek',
  heating_and_cooling_settings_heating_510:
           'Sikkerheds temperatur (altid 110gr.)',
  heating_and_cooling_settings_heating_509:
           'Minimum primary-secondary delta',
  heating_and_cooling_settings_heating_514: 'Fremløb setpunkt',
  heating_and_cooling_settings_heating_515: 'Varme',
  heating_and_cooling_settings_heating_515_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'On',
    },
  }),
  // Cooling
  heating_and_cooling_settings_cooling_title: 'Ikke i brug',
  heating_and_cooling_settings_cooling_600: 'Cooling setpoint',
  heating_and_cooling_settings_cooling_601: 'Minimum primary return',
  heating_and_cooling_settings_cooling_602:
           'Primary return compensation factor',
  heating_and_cooling_settings_cooling_605:
           'Maximum cooling temperature',
  heating_and_cooling_settings_cooling_606: 'Cooling setpoint minimum',
  heating_and_cooling_settings_cooling_607: 'Cooling setpoint maximum',
  heating_and_cooling_settings_cooling_611: 'Cooling enable',
  heating_and_cooling_settings_cooling_611_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'On',
    },
  }),
  // Secondary General
  heating_and_cooling_settings_secondary_general_title: 'Ikke i brug',
  heating_and_cooling_settings_secondary_general_512: 'Minimum Pressure',
  heating_and_cooling_settings_secondary_general_513: 'Maximum Pressure',
  heating_and_cooling_settings_secondary_general_603:
           'Wait time to switch heating and cooling',
  heating_and_cooling_settings_secondary_general_604:
           '6-way valve waiting time',
  // Changeover WKW
  heating_and_cooling_settings_changeover_wkw_title: 'Ikke i brug',
  heating_and_cooling_settings_changeover_wkw_1850:
           '3.5 pipe configuration',
  heating_and_cooling_settings_changeover_wkw_1850_list: JSON.stringify({
    list: {
      0: 'Heating',
      1: 'Cooling',
    },
  }),
  heating_and_cooling_settings_changeover_wkw_1851:
           'Cooling detection threshold',
  heating_and_cooling_settings_changeover_wkw_1852:
           'Heating detection threshold',
  heating_and_cooling_settings_changeover_wkw_1853:
           'Heating lowered setpoint',
  heating_and_cooling_settings_changeover_wkw_1854:
           'Lowered setpoint used',
  heating_and_cooling_settings_changeover_wkw_1854_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'On',
    },
  }),
  heating_and_cooling_settings_changeover_wkw_1855:
           'Predetection M2 position',
  heating_and_cooling_settings_changeover_wkw_1856: 'Detection interval',
  heating_and_cooling_settings_changeover_wkw_1857:
           'Detection interval timer',
  heating_and_cooling_settings_changeover_wkw_1858:
           'Predetection timeout',
  heating_and_cooling_settings_changeover_wkw_1859: 'Predetection timer',
  heating_and_cooling_settings_changeover_wkw_1860:
           'Configuration switch timer',
  heating_and_cooling_settings_changeover_wkw_1861: 'Switch timeout',
  heating_and_cooling_settings_changeover_wkw_1862:
           'Configuration switch threshold',
  heating_and_cooling_settings_changeover_wkw_1863:
           'Boost function mode',
  heating_and_cooling_settings_changeover_wkw_1863_list: JSON.stringify({
    list: {
      0: 'Disabled',
      1: 'Enabled',
    },
  }),
  heating_and_cooling_settings_changeover_wkw_1864:
           'Boost detection timer',
  heating_and_cooling_settings_changeover_wkw_1865:
           'Boost detection timeout',
  heating_and_cooling_settings_changeover_wkw_1866:
           'Maximum boost position',
  // Manual Operation
  settings_manual_operation_title: 'Manuel styring',
  settings_manual_operation_700: 'Manuel styring',
  settings_manual_operation_700_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'On',
    },
  }),
  settings_manual_operation_701: 'Ventil (M1)',
  settings_manual_operation_702: 'Ventil (M2)',
  settings_manual_operation_703: 'Pumpe',
  settings_manual_operation_703_list: JSON.stringify({
    list: {
      0: 'Off',
      1: 'On',
    },
  }),
  settings_manual_operation_704: 'Ikke i brug',
  settings_manual_operation_704_list: JSON.stringify({
    list: {
      0: 'Heating',
      1: 'Cooling',
    },
  }),
  settings_manual_operation_706: 'Varme krav status output',
  settings_manual_operation_706_list: JSON.stringify({
    list: {
      0: 'Varme',
      1: 'Køling',
    },
  }),
  settings_manual_operation_707: 'Ventil (M1)[%]',
  settings_manual_operation_708: 'Ventil (M2)[%]',
  settings_manual_operation_1801: 'Elektisch element TE-Booster',
  settings_manual_operation_1801_list: JSON.stringify({
    list: {
      0: 'Disabled',
      1: 'Enabled',
    },
  }),
  settings_manual_operation_709: 'Manuel styring timer',
  // Live Tap Water
  live_tap_water_title: 'Live Hot Water',
  live_tap_water_101: 'Varmt vand status',
  live_tap_water_107: 'Ventil (M1)',
  live_tap_water_200: 'Flow rate',
  live_tap_water_201: 'Varmt vands temperatur',
  live_tap_water_202: 'Primary supply',
  live_tap_water_203: 'Primary return',
  live_tap_water_1007: 'Primary flow M-Bus meter',
  live_tap_water_1008: 'Power',
  // Live CV
  live_cv_title: 'Live Heating',
  live_cv_100: 'Varme status',
  live_cv_108: 'Heating valve (M2)',
  live_cv_202: 'Primary supply',
  live_cv_203: 'Primary return',
  live_cv_204: 'Calculated heating setpoint',
  live_cv_205: 'Secondary supply',
  live_cv_206: 'Secondary return',
  live_cv_113: 'Rum termostat',
  live_cv_210: 'Secondary water pressure',
  live_cv_1007: 'Primary flow M-Bus meter',
  live_cv_1008: 'Power',
  // Live Cooling
  live_cooling_title: 'Live Cooling',
  live_cooling_104: 'Køle status',
  live_cooling_109: 'Pump',
  live_cooling_110: '6-way valve',
  live_cooling_113: 'Rum termostat',
  live_cooling_108: 'Cooling valve (M2)',
  live_cooling_202: 'Primary supply',
  live_cooling_204: 'Calculated heating setpoint',
  live_cooling_205: 'Secondary supply',
  live_cooling_206: 'Secondary return',
  // M Bus Limit DELETE ALL
  m_bus_limit_title: 'M Bus Limit',
  m_bus_limit_1500: 'Begrenzing inschakelen',
  m_bus_limit_1501: 'Serienummer begrenzing meter',
  m_bus_limit_1503: 'Begrens status',
  m_bus_limit_1504: 'Begrenzing configuratie DHW (h) en CH (l)',
  m_bus_limit_1505: 'Begrens vermogen',
  m_bus_limit_1506: 'Begrens flow',
  m_bus_limit_1507: 'Begrens retour temperatuur (Alleen voor CH)',
  // Meter 1
  m_bus_meter_1_title: 'Meter 1 (Heating)',
  m_bus_meter_1_0000: 'Serial number',
  m_bus_meter_1_0002: 'Manufacturer',
  m_bus_meter_1_0003: 'Meter type',
  m_bus_meter_1_0004: 'Status',
  m_bus_meter_1_0005: 'Flow',
  m_bus_meter_1_0006: 'Power',
  m_bus_meter_1_0007: 'Supply temperature',
  m_bus_meter_1_0008: 'Return temperature',
  m_bus_meter_1_0010: 'Accumulated heating energy',
  m_bus_meter_1_0012: 'Accumulated Volume',
  // Meter 2
  m_bus_meter_2_title: 'Meter 2 (Cooling)',
  m_bus_meter_2_0100: 'Serial umber',
  m_bus_meter_2_0102: 'Manufacturer',
  m_bus_meter_2_0103: 'Meter type',
  m_bus_meter_2_0104: 'Status',
  m_bus_meter_2_0105: 'Flow',
  m_bus_meter_2_0106: 'Power',
  m_bus_meter_2_0107: 'Supply temperature',
  m_bus_meter_2_0108: 'Return temperature',
  m_bus_meter_2_0110: 'Accumulated cooling energy',
  m_bus_meter_2_0112: 'Accumulated volume',
  // Meter 3
  m_bus_meter_3_title: 'Meter 3 (Electricity)',
  m_bus_meter_3_0200: 'Serial number',
  m_bus_meter_3_0202: 'Manufacturer',
  m_bus_meter_3_0203: 'Meter type',
  m_bus_meter_3_0204: 'Status',
  m_bus_meter_3_0206: 'Power',
  m_bus_meter_3_0210: 'Accumulated energy',
  m_bus_meter_3_0214: 'Current',
  // Meter 4
  m_bus_meter_4_title: 'Meter 4 (Water)',
  m_bus_meter_4_0300: 'Serial number',
  m_bus_meter_4_0302: 'Manufacturer',
  m_bus_meter_4_0303: 'Meter type',
  m_bus_meter_4_0304: 'Status',
  m_bus_meter_4_0305: 'Flow',
  m_bus_meter_4_0307: 'Supply temperature (if available)',
  m_bus_meter_4_0312: 'Accumulated volume',
  // Meter 5
  m_bus_meter_5_title: 'Meter 5 (Bi Directional)',
  m_bus_meter_5_0400: 'Serial number',
  m_bus_meter_5_0402: 'Manufacturer',
  m_bus_meter_5_0403: 'Meter type',
  m_bus_meter_5_0404: 'Status',
  m_bus_meter_5_0405: 'Flow',
  m_bus_meter_5_0406: 'Power',
  m_bus_meter_5_0407: 'Supply temperature',
  m_bus_meter_5_0408: 'Return temperature',
  m_bus_meter_5_0410: 'Accumulated heating energy',
  m_bus_meter_5_0412: 'Accumulated volume',
  m_bus_meter_5_0414: 'Accumulated cooling energy',
  // History Block Errors
  history_block_errors_title: 'History Block Errors - sorted by newest',
  history_block_errors_860: 'Block Alarm 1',
  history_block_errors_861: 'Block Alarm 2',
  history_block_errors_862: 'Block Alarm 3',
  history_block_errors_863: 'Block Alarm 4',
  history_block_errors_864: 'Block Alarm 5',
  history_block_errors_865: 'Block Alarm 6',
  history_block_errors_866: 'Block Alarm 7',
  history_block_errors_867: 'Block Alarm 8',
  history_block_errors_868: 'Block Alarm 9',
  history_block_errors_869: 'Block Alarm 10',
  history_block_errors_870: 'Block Alarm 11',
  history_block_errors_871: 'Block Alarm 12',
  history_block_errors_872: 'Block Alarm 13',
  history_block_errors_873: 'Block Alarm 14',
  history_block_errors_874: 'Block Alarm 15',
  // Events
  device_events_type_filter_label: 'Event type',
  device_events_type_filter_value_1: 'Audit',
  device_events_type_filter_value_2: 'Alarm',
  // Presets
  presets: 'Presets',
  presets_all: 'AllE',
  presets_active: 'AKTIVE',
  presets_inactive: 'INAKTIVE',
  presets_add_new: 'Tilføj ny',
  presets_filter_search: 'Søg',
  presets_filter_reset_button: 'Nulstil',
  presets_filter_delete_button: 'Slet',
  presets_table_name: 'Navn',
  presets_table_status: 'Status',
  presets_updated_date: 'Opdateret den',
  presets_action: 'Actions',
  presets_table_row_per_page: 'Rækker pr.side',
  presets_status_menu_list: JSON.stringify([
    {
      key: 'settings_hot_water',
      value: 'Indstillinger varmt vand',
      permission: 'settings-hot-water-tab-read',
    },
    {
      key: 'settings_heating_and_cooling',
      value: 'Indstillinger varme',
      permission: 'settings-heating-and-cooling-tab-read',
    },
    {
      key: 'manual_control',
      value: 'Manuel styring',
      permission: 'manual-control-tab-read',
    },
  ]),
  add_presets_title: 'Add preset',
  edit_presets_title: 'Edit preset',
  presets_form_name_required: 'Nulstil navn er påkrævet',
  presets_form_register_required: 'Register value is required',
  presets_form_add_preset_button: 'Gem ændringer',
  presets_form_edit_preset_button: 'Gem ændringer',
  presets_form_cancel_button: 'Annuller',
  presets_add_form_success: 'Preset created successfully!',
  presets_edit_form_success: 'Preset is updated successfully!',
  presets_form_api_error: 'Internal API - Presets Server Alarm',
  presets_no_record_found: 'No presets found',
  presets_deactivated_message: 'Preset is deactivated successfully',
  presets_activated_message: 'Preset is activated successfully',
  presets_bulk_no_user_is_selected_error_message:
           'Vælg enhed som skal deaktiveres',
  presets_bulk_deactivated_message:
           '{{ USERS_COUNT }} Presets are deactivated successfully',
  // Audits
  audits: 'Audits',
  audits_all: 'AllE',
  audits_active: 'Audit',
  audits_inactive: 'Alarm',
  audits_filter_search: 'Søg',
  audits_client_filter_label: 'Kunde',
  audits_error_code_filter_label: 'Alarm kode',
  audits_project_filter_label: 'Projekt',
  audits_order_number_filter_label: 'Ordrer nummer',
  audits_events_type_filter_label: 'Event type',
  audits_date_range_filter_label: 'Dato interval',
  audits_events_type_filter_value_1: 'Audit',
  audits_events_type_filter_value_2: 'Alarm',
  audits_reset_filters_button: 'Reset',
  audits_date: 'Dato',
  audits_device: 'Enhed',
  audits_client: 'Kunde',
  audits_message: 'Message',
  audits_table_row_per_page: 'Rækker pr.side',
  audits_table_no_record_found: 'Ingen søgninger fundet',
  // Settings
  settings_update_profile_title: 'Updatér profil',
  settings_notification_title: 'Notifikationer',
  settings_notification_window_exceptions_title:
           'Notify when operating window exceptions occur',
  settings_notification_device_error_title:
           'Notify when a device is in error mode',
  settings_notification_channels_title: 'Notifications channels',
  settings_notification_use_email_title: 'Brug email',
  /* Admins */
  admins: 'Administrator',
  admins_first_bread_crumb: 'Administrator',
  admins_filter_search: 'Søg',
  admins_add_new: 'Tilføj bruger',
  admins_filter_reset_button: 'Nulstil',
  admins_filter_delete_button: 'Deaktiver',
  admins_search: 'Søg',
  admins_all: 'ALLE',
  admins_active: 'AKTIVE',
  admins_inactive: 'INAKTIVE',
  admins_table_name: 'Navn',
  admins_table_address: 'Adresse',
  admins_table_role_id: 'Rolle',
  admins_table_status: 'Status',
  admins_table_added: 'Oprettelsesdato',
  admins_table_no_record_found: 'Ingen enheder fundet',
  admins_table_row_per_page: 'Rækker pr.side',
  admins_no_user_selected_for_de_activation_message:
           'Vælg enhed som skal deaktiveres',
  admins_bread_crumb_main_title: 'Administrator',
  add_admins_bread_crumb_first_link: 'Tilføj administrator',
  add_admins_form_header: 'Tilføj administrator',
  edit_admins_bread_crumb_first_link: 'Redigér administrator',
  edit_admins_form_header: 'Redigér administrator',
  admins_form_first_name_field: 'Fornavn',
  admins_form_last_name_field: 'Efternavn',
  admins_form_email_field: 'Email adresse',
  admins_form_official_email_field: 'Offical Email adresse',
  admins_form_phone_field: 'Telefonnummer',
  admins_form_official_phone_number_field: 'Offical Telefonnummer',
  admins_form_password_field: 'Adgangskode',
  admins_form_role_id_field: 'Rolle',
  admins_form_select_role_id_field: 'Vælg rolle',
  admins_form_street_number_field: 'vejnavn',
  admins_form_house_number_field: 'Husnummer',
  admins_form_neighbourhood_field: 'Yderligere information',
  admins_form_postal_code_field: 'Postnummer',
  admins_form_city_field: 'By',
  admins_form_country_field: 'Land',
  admins_form_email_verification_field: 'Email verificering',
  admins_form_email_verification_sub_title_field:
           'Disabling this will automatically send the admin a verification email',
  admins_form_add_admin_button: 'Gem ændringer',
  admins_form_edit_admin_button: 'Gem ændringer',
  admins_form_cancel_button: 'Annullere',
  admins_form_submitting_admin_button: 'Gemmer...',
  admins_first_name_required_form_error: 'Fornavn er påkrævet',
  admins_last_name_required_form_error: 'Efternavn er påkrævet',
  admins_email_required_form_error: 'Email er påkrævet',
  admins_email_valid_form_error: 'Skal være en gyldig email',
  admins_password_required_form_error: 'Adgangskode er påkrævet',
  admins_country_required_form_error: 'Land er påkrævet',
  admins_role_id_required_form_error: 'Rolle er påkrævet',
  admins_password_form_error:
           'Din adgangskode skal indeholde 8 tegn, mindst et stort bogstav, et tal og et specialtegn.',
  admins_add_form_success: 'Administrator er oprettet!',
  admins_edit_form_success: 'Administrator er opdateret!',
  admins_form_api_error: 'Internal Admins - API server error',
  admins_form_email_error: 'Emailen eksistere allerede',
  admins_no_record_found: 'Ingen administrator fundet',
  admins_deactivated_message: 'Administrator er deaktiveret',
  admins_activated_message: 'Administrator er aktiveret',
  admins_email_verified_title: 'Emailen er verificeret',
  admins_email_verified_sub_title:
           'Disabling this will automatically send the admin a verification email',
  admins_bulk_no_admin_is_selected_error_message:
           'Vælg enhed som skal deaktiveres',
  admins_bulk_deactivated_message:
           '{{ USERS_COUNT }} Administrator er deaktiveret',
  // Search Results
  search_result_title: 'Søgningsresultat: ',
  search_users: 'Bruger',
  search_clients: 'Kunder',
  search_service_clients: 'Service enheder',
  search_devices: 'Enheder',
  search_presets: 'Presets',
  search_projects: 'Projekter',
  // New Locales
  no_error_devices_found: 'Ingen fejlenheder fundet',
  project_single_first_bread_crumb: 'Projekt',
  project_single_second_bread_crumb: 'Se projekter',
  project_single_client_label: 'Kunde',
  project_single_service_client_label: 'Service kunde',
  project_single_devices_label: 'Enheder',
  project_single_edit_button: 'Redigér',
  project_single_devices_tab: 'Enheder',
  client_single_view_more_errors_button: 'Se flere',
  client_error_single_devices_tab: 'Alarm enheder',
  client_error_single_device_name: 'Enhed',
  client_error_single_device_error_code: 'Alarm kode',
  client_error_single_device_error_date_time: 'Dato',
  devices_bulk_edit_registers_button: 'Ændre indstillinger',
  devices_bulk_edit_ota_button: 'Opdatér enhed(er)',
  client_error_single_device_errors_table_row_per_page: 'Rækker pr.side',
  client_error_single_device_error_no_record_found:
           'Ingen søgninger fundet',
  devices_bulk_edit_registers_title: 'Device registers project update',
  devices_bulk_edit_registers_form_submit_button: 'Bulk update',
  devices_bulk_edit_registers_form_cancel_button: 'Annuller',
  devices_bulk_edit_registers_form_success:
           'Message is forwarded to selected device(s)',
  devices_bulk_edit_registers_form_error:
           'Please fill in atleast one register value',
  devices_bulk_edit_registers_form_api_error: 'Internal server error',
  devices_bulk_edit_ota_title: 'Device OTA bulk update',
  devices_bulk_edit_ota_form_firmware_field: 'Firmware',
  devices_bulk_edit_ota_form_submit_button: 'Opdatér enheder',
  devices_bulk_edit_ota_form_cancel_button: 'Annuller',
  devices_bulk_edit_ota_form_success:
           'Message is forwarded to selected devices',
  devices_bulk_edit_ota_form_firmware_required_error:
           'Firmware required',
  devices_bulk_edit_ota_form_api_error:
           'Internal Bulk - API Server Alarm',
  devices_bulk_edit_registers_selected_devices:
           'Devices Selected for Bulk Edit',
  // Enhedsstatus Table Pop Over
  custom_popover_min_value: 'Min. Value',
  custom_popover_max_value: 'Max. Value',
  custom_popover_list_options: 'Possible values',
  custom_popover_short_description: 'Beskrivelse',
  custom_popover_close_modal_button: 'Close window',
  // Warmtapwater PopOver Short Description
  custom_popover_300_short_description: 'Hot water setpoint',
  custom_popover_301_short_description: 'Preheat mode',
  custom_popover_302_short_description: 'Comfort setpoint',
  custom_popover_303_short_description: 'Minimum flow rate threshold',
  custom_popover_304_short_description: 'Hot water enable',
  // Termisk desinfektion PopOver Short Description
  custom_popover_400_short_description: 'Termisk desinfektion enabled',
  custom_popover_401_short_description: 'Termisk desinfektion setpoint',
  custom_popover_402_short_description: 'Termisk desinfektion burn time',
  custom_popover_403_short_description: 'Termisk desinfektion wait time',
  // TE-Booster PopOver Short Description
  custom_popover_1800_short_description: 'TE-Booster Enabled',
  custom_popover_1804_short_description: 'TE-Booster setpoint',
  custom_popover_1805_short_description:
           'TE-Booster setpoint Hysteresis',
  custom_popover_1812_short_description: 'TE-Booster setpoint timeout',
  custom_popover_1813_short_description: 'TE-Booster setpoint timer',
  custom_popover_1815_short_description: 'TE-Booster threshold',
  custom_popover_1816_short_description: 'TE-Booster taped volume',
  // Heating PopOver Short Description
  custom_popover_500_short_description: 'Heating maximum setpoint',
  custom_popover_501_short_description: 'Heating minimum setpoint',
  custom_popover_506_short_description: 'Maximum primary return',
  custom_popover_507_short_description:
           'Primary return compensation factor',
  custom_popover_508_short_description:
           'Delta primary-secondary setpoint',
  custom_popover_510_short_description: 'Maximum heating temperature',
  custom_popover_509_short_description:
           'Minimum primary-secondary delta',
  custom_popover_514_short_description: 'Heating setpoint',
  custom_popover_515_short_description: 'Heating enable',
  // Cooling PopOver Short Description
  custom_popover_600_short_description: 'Cooling setpoint',
  custom_popover_601_short_description: 'Minimum primary return',
  custom_popover_602_short_description:
           'Primary return compensation factor',
  custom_popover_605_short_description: 'Maximum cooling temperature',
  custom_popover_606_short_description: 'Cooling setpoint minimum',
  custom_popover_607_short_description: 'Cooling setpoint maximum',
  custom_popover_611_short_description: 'Cooling enable',
  // Secondary General PopOver Short Description
  custom_popover_512_short_description: 'Minimum Pressure',
  custom_popover_513_short_description: 'Maximum Pressure',
  custom_popover_603_short_description:
           'Wait time to switch heating and cooling',
  custom_popover_604_short_description: '6-way valve waiting time',
  // Changeover WKW PopOver Short Description
  custom_popover_1850_short_description: '3.5Pipe Configuration',
  custom_popover_1851_short_description: 'Cooling detection threshold',
  custom_popover_1852_short_description: 'Heating detection threshold',
  custom_popover_1853_short_description: 'Heating lowered setpoint',
  custom_popover_1854_short_description: 'Lowered setpoint used',
  custom_popover_1855_short_description: 'Predetection M2 position',
  custom_popover_1856_short_description: 'Detection interval',
  custom_popover_1857_short_description: 'Detection interval timer',
  custom_popover_1858_short_description: 'Predetection timeout',
  custom_popover_1859_short_description: 'Predetection timer',
  custom_popover_1860_short_description: 'Configuration switch timer',
  custom_popover_1861_short_description: 'Switch timeout',
  custom_popover_1862_short_description:
           'Configuration switch threshold',
  custom_popover_1863_short_description: 'Boost function mode',
  custom_popover_1864_short_description: 'Boost detection timer',
  custom_popover_1865_short_description: 'Boost detection timeout',
  custom_popover_1866_short_description: 'Maximum boost position',
  // Manual Operation PopOver Short Description
  custom_popover_700_short_description: 'Manuel styring enable',
  custom_popover_701_short_description: 'Ventil (M1)',
  custom_popover_702_short_description: 'Ventil (M2)',
  custom_popover_703_short_description: 'Pump',
  custom_popover_704_short_description: '6-way valve',
  custom_popover_706_short_description: 'Varme krav status output',
  custom_popover_707_short_description: 'Ventil (M1)[%]',
  custom_popover_708_short_description: 'Ventil (M2)[%]',
  custom_popover_1801_short_description: 'Elektisch element TE-Booster',
  custom_popover_709_short_description: 'Manuel styring timeout timer',
  // Warmtapwater PopOver Long Description
  custom_popover_300_long_description: 'DHW Setpoint',
  custom_popover_301_long_description: 'Preheat mode',
  custom_popover_302_long_description: 'Comfort Setpoint',
  custom_popover_303_long_description: 'Flow rate start threshold',
  custom_popover_304_long_description: 'DHW Enable',
  // Termisk desinfektion PopOver Long Description
  custom_popover_400_long_description: 'Aleg Enabled',
  custom_popover_401_long_description: 'Aleg Setpoint',
  custom_popover_402_long_description: 'Aleg Burn Time',
  custom_popover_403_long_description: 'Aleg Wait Time',
  // TE-Booster PopOver Long Description
  custom_popover_1800_long_description: 'TE-Booster Enabled',
  custom_popover_1804_long_description: 'TE-Booster Setpoint',
  custom_popover_1805_long_description: 'TE-Booster Setpoint Hysteresis',
  custom_popover_1812_long_description: 'TE-Booster Setpoint Timeout',
  custom_popover_1813_long_description: 'TE-Booster Setpoint Timer',
  custom_popover_1815_long_description: 'TE-Booster Threshold',
  custom_popover_1816_long_description: 'TE-Booster Taped Volume',
  // Heating PopOver Long Description
  custom_popover_500_long_description: 'CH Max Setpoint',
  custom_popover_501_long_description: 'CH Min Setpoint',
  custom_popover_506_long_description: 'Max Primary Return',
  custom_popover_507_long_description: 'Max Primary Return Percentage',
  custom_popover_508_long_description: 'Delta Prim CH Setpoint',
  custom_popover_510_long_description: 'Max Heating Temperature',
  custom_popover_509_long_description: 'Delta Prim Min',
  custom_popover_514_long_description: 'CH Setpoint',
  custom_popover_515_long_description: 'CH Enable',
  // Cooling PopOver Long Description
  custom_popover_600_long_description: 'CoolTempSetpoint',
  custom_popover_601_long_description: 'Cool MaxPrimary Return',
  custom_popover_602_long_description:
           'Cool % of Max Setpoint compensation',
  custom_popover_605_long_description: 'Max Cooling Temp',
  custom_popover_606_long_description: 'Cool setpoint min',
  custom_popover_607_long_description: 'Cool setpoint max',
  custom_popover_611_long_description: 'Cooling Enable',
  // Secondary General PopOver Long Description
  custom_popover_512_long_description: 'CH Min Pressure',
  custom_popover_513_long_description: 'CH Max Pressure',
  custom_popover_603_long_description: 'CoolHeatWaitingTime',
  custom_popover_604_long_description: '6way valve waiting time',
  // Changeover WKW PopOver Long Description
  custom_popover_1850_long_description: '3.5Pipe Configuration',
  custom_popover_1851_long_description: 'Cooling detection threshold',
  custom_popover_1852_long_description: 'Heating detection threshold',
  custom_popover_1853_long_description: 'Heating lowered setpoint',
  custom_popover_1854_long_description: 'Lowered setpoint used',
  custom_popover_1855_long_description: 'Predetection MCV2 position',
  custom_popover_1856_long_description: 'Detection interval',
  custom_popover_1857_long_description: 'Detection interval timer',
  custom_popover_1858_long_description: 'Predetection timeout',
  custom_popover_1859_long_description: 'Predetection timer',
  custom_popover_1860_long_description: 'Configuration switch timer',
  custom_popover_1861_long_description: 'Switch timeout',
  custom_popover_1862_long_description: 'Configuration switch threshold',
  custom_popover_1863_long_description: 'Boost function mode',
  custom_popover_1864_long_description: 'Boost detection timer',
  custom_popover_1865_long_description: 'Boost detection timeout',
  custom_popover_1866_long_description: 'Maximum boost position',
  // Manual Operation PopOver Long Description
  custom_popover_700_long_description: 'Manuel styring enabled',
  custom_popover_701_long_description: 'MCV-1 Manuel styring',
  custom_popover_702_long_description: 'MCV-2 Manuel styring',
  custom_popover_703_long_description: 'Pump (P1) Manuel styring',
  custom_popover_704_long_description: 'Valve1',
  custom_popover_706_long_description: 'Varme krav status output',
  custom_popover_707_long_description: 'MCV-1 Manuel styring[%]',
  custom_popover_708_long_description: 'MCV-1 Manuel styring[%]',
  custom_popover_1801_long_description: 'Elektisch element TE-Booster',
  custom_popover_709_long_description: 'Manuel styring timeout',
  // API keys
  client_single_api_key_approve_api_key_label: 'API Key',
  client_single_api_key_app_id_label: 'App Id',
  client_single_api_key_approve_api_key_button: 'Approve Account',
  client_single_api_key_suspend_api_key_button: 'Suspend Application',
  client_single_api_key_unblock_application_button:
           'Unblock Application',
  client_single_api_key_generate_api_key_button: 'Generate API Key',
  client_single_api_key_generate_api_key_success:
           'API Key is Generated Successfully',
  client_single_api_key_generate_api_limit_error:
           'Sorry, we cannot regenerate a new API Key as regenerate limit is reached.',
  client_single_api_key_generate_api_3scale_error:
           '3Scale API Alarm. Please contact Administrator.',
  client_single_api_application_suspend_success:
           '3Scale Application is suspended successfully',
  client_single_api_application_unblock_success:
           '3Scale Application is Unblocked successfully',
  client_single_api_application_suspend_error:
           'Application is already suspended',
  client_single_api_application_unblock_errpr:
           'Application is already unblocked',
  client_single_api_key_account_pending_approval:
           'Your account is not approved yet. Please contact Administrator for more details.',
  client_single_api_key_account_suspended:
           'Your account is suspended. Please contact Administrator for more details.',
  // Clients Form
  three_scale_temporary_password_subject:
           'Fortes Energy - 3Scale Account Created',
  clients_form_password_field: 'Password',
  clients_password_required_form_error:
           'Din adgangskode skal indeholde 8 tegn, mindst et stort bogstav, et tal og et specialtegn.',
  // Devices Form
  devices_form_mbus_meters_are_not_connected:
           'MBus Meters are not connected.',
  MBUS_METER_HEATING: 'Heat meter',
  MBUS_METER_COOLING: 'Cool meter',
  MBUS_METER_ELECTRICITY: 'Electricity meter',
  MBUS_METER_WATER: 'Water meter',
  MBUS_METER_BI_DIRECTIONAL: 'Heat/cool meter',
  meter_enabled: 'Enabled',
  meter_disabled: 'Disabled',
  devices_mbus_registers_toggle_register_status_success:
           'The message has been successfully sent to device.',
  MBUS_METER_SERIAL_NUMBER: 'Serienummer',
  MBUS_METER_MANUFACTURER: 'Manufacturer',
  MBUS_METER_METER_TYPE: 'Meter type',
  MBUS_METER_STATUS: 'Status',
  MBUS_METER_FLOW: 'Flow',
  MBUS_METER_POWER: 'Power',
  MBUS_METER_SUPPLY_TEMPERATURE: 'Supply temperature',
  MBUS_METER_RETURN_TEMPERATURE: 'Return temperature',
  MBUS_METER_HEATING_ENERGY: 'Accumulated heating energy',
  MBUS_METER_ELECTRICITY_ENERGY: 'Accumulated Electricity',
  MBUS_METER_VOLUME: 'Accumulated volume',
  MBUS_METER_COOLING_ENERGY: 'Accumulated cooling energy',
  MBUS_METER_CURRENT: 'Current',
  // Device Set Registers Per Device
  device_single_device_registers_tab: 'Set Registers',
  device_single_device_registers_menu_list: JSON.stringify([
    {
      key: 'operating_state',
      value: 'Oversigt',
      permission: 'operating-state-tab-read',
    },
  ]),
  device_status_interval: 'Interval',
  device_status_interval_in_seconds: 'Sekunder',
  device_single_set_registers_error:
           'Please select atleast one register to proceed.',
  device_single_set_registers_success:
           'Message is successfully forwarded to the device.',
  device_single_set_register_form_interval_required_error:
           'Interval (seconds) are required',
  device_single_set_register_form_interval_numeric_error:
           'Interval (seconds) must be numeric',
  device_single_set_register_form_send_button: 'Send',
  device_single_set_register_form_cancel_button: 'Annuller',
  clients_3scale_email_error: 'Emailen eksistere allerede in 3Scale',
  page_title_appending_title: ' | Fortes Data Management',
  project_single_device_registers_tab: 'Set Registers',
  audits_user: 'Bruger',
  audits_system_generated: 'Application',
  device_single_project_set_registers_error:
           'Message is not forwarded because there are no devices linked with the project',
  audits_user_filter_label: 'Bruger',
  audits_event_type_field: 'Event Type',
  audits_select_event_type_field: 'Select Event Type',
  audits_select_event_type_incoming_option: 'Incoming',
  audits_select_event_type_outgoing_option: 'Outgoing',
  audits_select_event_type_system_generated_option: 'Application',
  audits_table_loading: 'Loading Events',
  appliance_type_reference_table_appliance_name: 'Navn',
  appliance_type_reference_table_appliance_type: 'Appliance Type',
  appliance_type_reference_table_updated_at: 'Updated At',
  sidebar_appliance_type_reference_menu: 'Appliance Types',
  appliance_types_register_form_send_button: 'Gem',
  appliance_types_register_form_cancel_button: 'Annuller',
  appliance_types: 'Appliance Types',
  appliance_types_table_no_record_found: 'Ingen enheder fundet',
  appliance_types_table_row_per_page: 'Rækker pr.side',
  add_appliance_type: 'Add Appliance Type',
  add_appliance_types_form_header: 'Add Appliance Type',
  edit_appliance_types_form_header: 'Edit Appliance Type',
  appliance_types_add_new: 'Tilføj ny',
  appliance_type_form_name_label: 'Navn',
  appliance_type_form_type_label: 'Appliance Type',
  appliance_type_form_select_type_label: 'Select Appliance Type',
  appliance_name_form_required_error: 'Navn er påkrævet',
  appliance_type_form_required_error: 'Appliance Type is required',
  add_appliance_type_form_success:
           'Appliance Type record is created successfully',
  add_appliance_type_form_api_error: 'Intern serveralarm',
  edit_appliance_type_form_success:
           'Appliance Type record is is updated successfully',
  edit_appliance_type_form_api_error: 'Intern serveralarm',
  appliance_type_reference_table_filter_reset_button: 'Reset',
  appliance_type_reference_table_search: 'Søg',
  appliance_type_reference_status_menu_list: JSON.stringify([
    {
      key: 'operating_state',
      value: 'Oversigt',
      permission: 'operating-state-tab-read',
    },
    {
      key: 'settings_hot_water',
      value: 'Indstillinger varmt vand',
      permission: 'settings-hot-water-tab-read',
    },
    {
      key: 'settings_heating_and_cooling',
      value: 'Indstillinger varme',
      permission: 'settings-heating-and-cooling-tab-read',
    },
    {
      key: 'manual_control',
      value: 'Manuel styring',
      permission: 'manual-control-tab-read',
    },
  ]),
  audits_filter_button: 'Filters',
  device_single_no_registers_are_available:
           'Registers are not available',
  device_single_device_diagnostics_tab: 'Diagnostics',
  device_single_dp_measurement_back_button: 'Tidligere',
  device_single_diagnostic_next_button: 'Næste',
  device_single_dp_measurement_start_button: 'Start',
  device_single_diagnostict_finish_button: 'Færdig',
  device_single_diagnostic_stop_button: 'Stop',
  device_single_dp_measurement_refresh_button: 'Refresh',
  device_single_dp_measurement_event_logs_title: 'Output',
  device_single_dp_measurement_select_mbus_meter_button:
           'Select M-Bus Meter',
  device_single_dp_measurement_step_1_title:
           'Differential pressure calculation - Choose the flow',
  device_single_dp_measurement_pressure_difference_stepper_1_title:
           'Choose the flow',
  device_single_dp_measurement_step_1_flow_1_label:
           'Differential pressure calculation',
  device_single_dp_measurement_step_1_flow_2_label: 'Domestic hot water',
  device_single_dp_measurement_pressure_difference_step_2_title:
           'Differential pressure calculation - Explanation of the wizard',
  device_single_dp_measurement_pressure_difference_stepper_2_title:
           'Explanation of the wizard',
  device_single_dp_measurement_pressure_difference_step_2_description:
           'Available differential pressure is always an issue in collective networks. With the powerful combination of AquaHeat aflversets and the Fortes Data Management platform, you can easily perform a differential pressure calculation at any point in your collective network through a user-friendly module. This gives an excellent first indication of a possible cause. With this diagnosis we enable you to calculate an initial indication of the available pressure difference. <br /><br /><strong>Required: Heat meter must be connected to the Mercury Communication Module</strong>',
  device_single_dp_measurement_pressure_difference_step_3_title:
           'Differential pressure calculation - Controlling the right components',
  device_single_dp_measurement_pressure_difference_stepper_3_title:
           'Handbediening register',
  device_single_dp_measurement_step_3_pressure_difference_output_1:
           'Output 1 - Turning on the Manuel styring',
  device_single_dp_measurement_pressure_difference_output_2:
           'Output 2 - Opening the control valve',
  device_single_dp_measurement_pressure_difference_step_3_mbus_meter_title:
           'Select M-Bus Meter (for multiple meters, it concerns the energy meter that registers the tap water.)',
  device_single_dp_measurement_pressure_difference_stepper_4_title:
           'Select the right M-Bus Meter',
  device_single_dp_measurement_pressure_difference_step_4_flow_label:
           'Flow',
  device_single_dp_measurement_pressure_difference_stepper_5_title:
           'Calculation of the differential pressur',
  device_single_dp_measurement_pressure_difference_output_3:
           'Output 3 - Select meter',
  device_single_dp_measurement_pressure_difference_output_4:
           'Output 4 - Calculation of the differential pressure',
  device_single_dp_measurement_pressure_difference_stepper_6_title:
           'Resultaat',
  device_single_dp_measurement_pressure_difference_step_6_description:
           'Your differential pressure is OK! No further actions are required. ',
  device_single_dp_measurement_download_button: 'Download resultaten',
  device_single_dp_measurement_pressure_difference_step_7_description:
           'De drukverschil berekening is succesvol afgerond.',
  device_single_general_title: 'General',
  device_single_hotwater_title: 'Domestic hot water',
  device_single_dp_measurement_hot_water_flow_1_stepper_1_title:
           'Kies flow',
  device_single_dp_measurement_hot_water_flow_1_stepper_2_title:
           'Uitleg van de wizard',
  device_single_dp_measurement_hot_water_flow_1_stepper_3_title:
           'Open Faucet',
  device_single_dp_measurement_hot_water_flow_1_stepper_4_title:
           'Checking Faucet and Hot water valve',
  device_single_dp_measurement_hot_water_flow_1_stepper_5_title:
           'MBus Meter',
  device_single_dp_measurement_hot_water_flow_1_stepper_6_title:
           'Calculation of Hot Water Flow',
  device_single_dp_measurement_hot_water_flow_1_stepper_7_title:
           'Results',
  device_single_dp_measurement_hot_water_flow_1_step_2_title:
           'DP Measurement - Explanation of Flow',
  device_single_dp_measurement_hot_water_flow_1_step_2_description:
           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu proin amet vitae etiam. In mauris habitasse turpis quis tortor, integer magna dictum condimentum. In pellentesque proin eu ut viverra quam. Consequat pharetra proin lacus, ultrices enim. Tincidunt porta tortor lacinia varius mattis pellentesque. In augue sit vitae leo sed. Nibh sed mi bibendum convallis viverra vestibulum pretium commodo. Nibh quam vulputate malesuada sodales. Curabitur nisl leo quis sed vitae cras. Tempor risus, a volutpat consequat eget faucibus nulla fermentum. Ut dignissim proin nulla et congue mattis. Euismod et ipsum quis habitant in enim viverra. Mattis varius quis arcu cras vestibulum nunc mattis consectetur in. Tempus mauris egestas elit malesuada ullamcorper aliquet cras adipiscing ',
  device_single_dp_measurement_hot_water_flow_1_step_3_title:
           'Open Faucet',
  device_single_dp_measurement_hot_water_flow_1_step_3_faucet_error:
           'Flow Sensor Defect',
  device_single_dp_measurement_hot_water_flow_1_step_3_faucet_error_one_try_left:
           'Try one more time',
  diagnostic_flows_form_open_faucet_title: 'Faucet is opened by client',
  diagnostic_flows_form_open_faucet_off_option_description:
           'Faucet is not opened by client',
  device_single_dp_measurement_hot_water_flow_1_output_1:
           'Output 1 - Varmt vand status',
  device_single_dp_measurement_hot_water_flow_1_output_2:
           'Output 2 - Ventil (M1)',
  device_single_dp_measurement_hot_water_flow_1_step_3_mbus_meter_title:
           'Select MBus Meter',
  device_single_dp_measurement_hot_water_flow_1_step_4_flow_label:
           'Flow',
  device_single_dp_measurement_step_4_hot_water_flow_1_output_3:
           'Output 3 - Select Meter',
  device_single_dp_measurement_hot_water_flow_1_output_4:
           'Output 4 - Mbus Flow',
  device_single_dp_measurement_hot_water_flow_1_output_5:
           'Output 5 - Calculation of Hot Water Flow',
  device_single_dp_measurement_hot_water_flow_1_step_7_description:
           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu proin amet vitae etiam. In mauris habitasse turpis quis tortor, integer magna dictum condimentum. In pellentesque proin eu ut viverra quam. Consequat pharetra proin lacus, ultrices enim. Tincidunt porta tortor lacinia varius mattis pellentesque. In augue sit vitae leo sed. Nibh sed mi bibendum convallis viverra vestibulum pretium commodo. Nibh quam vulputate malesuada sodales. Curabitur nisl leo quis sed vitae cras. Tempor risus, a volutpat consequat eget faucibus nulla fermentum. Ut dignissim proin nulla et congue mattis. Euismod et ipsum quis habitant in enim viverra. Mattis varius quis arcu cras vestibulum nunc mattis consectetur in. Tempus mauris egestas elit malesuada ullamcorper aliquet cras adipiscing ',
  device_single_dp_measurement_hot_water_flow_1_step_8_description:
           'Hot Water - Flow 1 Diagnostics is finished',
  device_single_heating_title: 'Heating',
  device_single_dp_measurement_step_1_flow_3_label: 'Flow 1',
  device_single_cooling_title: 'Cooling',
  device_single_dp_measurement_step_1_flow_4_label: 'Flow 1',
  device_single_temperature_graph_loading_data: 'Loading graph',
  device_single_temperature_no_graph_data_error:
           'The graph is unavailable',
  device_graph_week_filter: 'W',
  device_graph_weeks_filter: 'W',
  device_graph_day_filter: 'D',
  device_graph_days_filter: 'D',
  device_graph_hours_filter: 'H',
  device_single_registers_table_numeric_error:
           'Only numeric values are allowed',
  device_single_registers_table_min_max_error:
           'Value must be between min_value and max_value',
  MBUS_METER_TYPE_WATER: 'Water',
  MBUS_METER_TYPE_ELECTRICITY: 'Electricity',
  MBUS_METER_TYPE_HEATING: 'Heat',
  MBUS_METER_TYPE_BIDIRECTIONAL: 'Heat/cool',
  MBUS_METER_TYPE_COOLING: 'Cooling',
  UNKNOWN_MANUFACTURER: 'Unknown manufacturer',
  commissoning_form_header: 'Commissoning',
  commissoning_form_serial_number_scan_button: 'Scan',
  commissoning_form_heat_interface_unit_scan_button:
           'Scan Fjernvarmeunit',
  commissoning_form_search_field: 'Mercurius serienummer',
  sidebar_commissioning_overview: 'Commissioning',
  sidebar_commissioning: 'Commissioning',
  commissoning_form_success: 'Enheden er opdateret!',
  commissoning_error_message: 'Enheden er ikke fundet',
  commissoning_device_submit_button: 'Opdatér enhed',
  swapping_form_header: 'Device Swapping',
  swapping_old_device_title: 'Gammel enhed',
  swapping_new_device_title: 'Ny enhed',
  swapping_form_scan_button: 'Scan',
  swapping_form_search_field: 'Mercurius serienummer',
  sidebar_swapping_overview: 'Device Swapping Overview',
  sidebar_swapping: 'Swapping',
  swapping_form_success: 'Device is swapped successfully!',
  swapping_old_device_error_message: 'Gammel enhed ikke fundet',
  swapping_new_device_error_message: 'Ny enhed ikke fundet',
  swapping_audit_message: 'Device is swapped',
  swapping_device_submit_button: 'Swap devices',
  email_device_swapping_subject:
           'Fortes Data Management - Device swapping',
  email_device_swapping_recipient_name: 'Fortes Data Management',
  email_device_swapping_title:
           'Fortes Data Management - Device Swapping',
  email_device_swapping_old_device_title: 'Gammel enhed',
  email_device_swapping_new_device_title: 'Ny enhed',
  email_device_swapped_by: 'Device swapped by:',
  swapping_device_unique_error: 'Enheden eksistere allerede',
  clear_device_form_header: 'Nulstil enhed',
  clear_device_form_scan_button: 'Scan',
  clear_device_form_search_field: 'Mercurius serienummer',
  sidebar_clear_device_overview: 'Nulstil enhed',
  sidebar_clear_device: 'Nulstil enhed',
  clear_device_form_success: 'Enheden er opdateret!',
  clear_device_error_message: 'Enheden er ikke fundet',
  clear_device_submit_button: 'Nulstil enhed',
  clear_device_audit_message: 'Device is cleared',
  device_single_swapped_devices_tab: 'Swapped devices',
  devices_single_swapped_device_name: 'Enhedsnavn',
  devices_single_swapped_device_firmware: 'Firmware',
  devices_single_swapped_device_type: 'Enhedstype',
  devices_single_swapped_device_box_serial_number:
           'Serienummer fjernvarmeunit',
  devices_single_swapped_device_sim_card_number: 'Simkort nummer',
  devices_single_swapped_device_created_at: 'Swapped date',
  devices_single_swapped_device_name_search: 'Søg',
  devices_single_swapped_swapped_device_name: 'Swapped from',
  devices_single_swapped_device_table_no_record_found:
           'Ingen enheder fundet',
  devices_single_swapped_device_table_loading: 'Loading',
  devices_single_swapped_device_table_row_per_page: 'Rækker pr.side',
  sidebar_swapped_devices_overview: 'Swapped Enheds overblik',
  sidebar_swapped_devices: 'Swapped devices',
  swapped_devices_form_header: 'Swapped devices',
  devices_bulk_edit_registers_form_no_devices_error:
           'Please select devices to proceed.',
  devices_bulk_edit_firmware_modal_title:
           'Are you sure you want to run a bulk update?',
  devices_bulk_edit_firmware_modal_subtitle:
           'You want to Bulk Update Firmware version ##firmware_value## for ##total_devices## devices?',
  devices_bulk_edit_registers_modal_title:
           'Are you sure you want to run a bulk update?',
  devices_bulk_edit_registers_modal_subtitle:
           'You want to Bulk Update Registers for ##total_devices## devices?',
  devices_bulk_edit_registers_modal_cancel_button: 'Annuller',
  devices_bulk_edit_registers_modal_submit_button: 'Bekræft',
  devices_bulk_edit_firmware_modal_cancel_button: 'Annuller',
  devices_bulk_edit_firmware_modal_submit_button: 'Bekræft',
  devices_bulk_export_button: 'Export Devices',
  projects_bulk_export_button: 'Export Projects',
  project_devices_bulk_export_button: 'Export Projects',
  devices_bulk_export_file_name: 'Device Bulk Export',
  projects_bulk_export_file_name: 'Project Bulk Export',
  project_devices_bulk_export_file_name: 'Device Bulk Export',
  sidebar_reporting_overview: 'Reporting',
  sidebar_bulk_edit_ota_reporting: 'OTA Report',
  sidebar_bulk_reporting_registers_table_title: 'Registers Report',
  edit_ota_reporting: 'OTA Audit',
  reporting_ota_bulk_export_button: 'Export',
  reporting_ota_filter_reset_button: 'Reset',
  reporting_ota_table_filter_search: 'Søg',
  reporting_ota_table_created_at: 'Dato',
  reporting_ota_table_total_devices: 'Antal enheder',
  reporting_ota_table_user: 'Bruger',
  reporting_ota_table_updated_at: 'Dato',
  reporting_ota_table_message: 'Besked',
  reporting_ota_table_no_record_found: 'Ingen enheder fundet',
  reporting_ota_table_row_per_page: 'Rækker pr.side',
  edit_ota_reporting_details: 'OTA Audit Details',
  reporting_ota_details_all_tab: 'Alle',
  reporting_ota_details_pending_tab: 'Pending',
  reporting_ota_details_success_tab: 'Succes',
  reporting_ota_details_failure_tab: 'Mislykkes',
  reporting_ota_details_bulk_export_button: 'Eksporter',
  reporting_ota_details_filter_reset_button: 'Reset',
  reporting_ota_details_table_filter_search: 'Søg',
  reporting_ota_details_message: 'Firmware version updated to',
  reporting_ota_details_table_created_at: 'Dato',
  reporting_ota_details_table_device: 'Enhed',
  reporting_ota_details_table_user: 'Bruger',
  reporting_ota_details_table_old_firmware_version:
           'Old Firmware Version',
  reporting_ota_details_table_current_firmware_version:
           'Current Firmware Version',
  reporting_ota_details_table_current_status: 'Status',
  reporting_ota_details_table_message: 'Besked',
  reporting_ota_details_table_no_record_found: 'Ingen enheder fundet',
  reporting_ota_details_table_row_per_page: 'Rækker pr.side',
  reporting_ota_bulk_export_file_name: 'Bulk OTA Reporting',
  reporting_ota_details_bulk_export_file_name:
           'Bulk OTA Details Reporting',
  devices_single_swapped_bulk_export_button_file_name:
           'Swapped Devices Reporting',
  devices_single_swapped_bulk_export_button: 'Export',
  reporting_registers_table_title: 'Registers Audit',
  reporting_registers_table_created_at: 'Dato',
  reporting_registers_table_total_devices: 'Antal enheder',
  reporting_registers_table_user: 'Bruger',
  reporting_registers_table_message: 'Besked',
  reporting_registers_bulk_export_button: 'Eksporter',
  reporting_registers_filter_reset_button: 'Reset',
  reporting_registers_table_filter_search: 'Søg',
  reporting_registers_table_no_record_found: 'Ingen enheder fundet',
  reporting_registers_table_row_per_page: 'Rækker pr.side',
  reporting_registers_table_bulk_export_file_name:
           'Bulk Registers Reporting',
  registers_reporting_details_title: 'Registers Audit Details',
  reporting_registers_details_bulk_export_button: 'Eksporter',
  reporting_registers_details_filter_reset_button: 'Reset',
  reporting_registers_details_table_filter_search: 'Søg',
  reporting_registers_details_table_created_at: 'Dato',
  reporting_registers_details_table_device: 'Enhed',
  reporting_registers_details_table_user: 'Bruger',
  reporting_registers_details_table_message: 'Besked',
  reporting_registers_details_table_failed_message:
           'Firmware version is not updated to',
  reporting_registers_details_table_pending_message:
           'Firmware version updating to',
  reporting_registers_details_table_successful_message:
           'Firmware version updated to',
  reporting_registers_details_table_no_record_found:
           'Ingen enheder fundet',
  reporting_registers_details_table_row_per_page: 'Rækker pr.side',
  reporting_registers_details_bulk_export_file_name:
           'Bulk Registers Details Reporting',
  devices_bulk_edit_ota_form_firmware_field_select_label:
           'Select Firmware',
  dashboard_users_overview: 'Bruger overblik',
  dashboard_users_title: 'Brugere',
  dashboard_devices_overview: 'Enheds overblik',
  dashboard_devices_title: 'Enheder',
  dashboard_commissioning_overview: 'Commissioning overview',
  dashboard_commissioning_title: 'Commissioning',
  devices_filters: 'Filters:',
  devices_submit_button: 'Submit',
  device_single_device_documentation_tab: 'Docs',
  device_single_documentation_brochure_button: 'Brochure',
  device_single_documentation_installation_manual_button:
           'Installation Manual',
  device_single_documentation_dimensional_drawing_button:
           'Dimensional Drawing',
  device_single_documentation_parts_drawing_button: 'Parts Drawing',
  device_single_documentation_flow_direction_button: 'Flow Direction',
  device_single_documentation_principle_diagram_button:
           'Principle Diagram',
  device_single_documentation_information_end_user_button:
           'Information End User',
  device_single_documentation_malfunction_key_button: 'Malfunction Key',
  reporting_ota_failed_message: 'Firmware version is not updated to',
  reporting_ota_pending_message: 'Firmware version updating to',
  reporting_ota_successful_message: 'Firmware version updated to',
  ota_pending: 'Pending',
  ota_success: 'Succesfuldt',
  ota_failed: 'Fejlet',
  clear_devices_modal_title: 'Nulstil enhed',
  clear_devices_modal_subtitle:
           'Are you you want to clear this device with serial number <strong>##serial_number##</strong>',
  clear_devices_modal_cancel_button: 'Annulere',
  clear_devices_modal_submit_button: 'Nulstil enhed',
  sidebar_fortes_roles: 'Roller',
  sidebar_fortes_permissions: 'Permissions',
  roles_all: 'Alle',
  roles_active: 'Aktive',
  roles_inactive: 'Inaktive',
  roles_filter_delete_button: 'Slet',
  roles_table_identifier: 'Identifier',
  roles_table_name_en: 'Navn (EN)',
  roles_table_name_nl: 'Navn (NL)',
  roles_table_description_en: 'Beskrivelse (EN)',
  roles_table_description_nl: 'Beskrivelse (NL)',
  roles_table_status: 'Status',
  roles_table_updated_at: 'Updated At',
  roles_filter_search: 'Søg',
  roles_no_record_found: 'Ingen enheder fundet',
  roles_table_row_per_page: 'Rækker pr.side',
  roles_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
  roles_deactivated_message: 'Role is deactivated successfully',
  roles_activated_message: 'Role is activated successfully',
  roles_bulk_deactivated_message:
           '##ROLES_COUNT## Roles are deactivated successfully',
  roles_title: 'Roller',
  roles_add_new: 'Tilføj ny',
  roles_bread_crumb_main_title: 'Roller',
  add_roles_bread_crumb_first_link: 'Tilføj rolle',
  edit_roles_bread_crumb_first_link: 'Rediger rolle',
  add_roles_form_header: 'Tilføj rolle',
  edit_roles_form_header: 'Rediger rolle',
  roles_form_role_identifier_field: 'Identifier',
  roles_identifier_required_form_error: 'Identifier is required',
  roles_form_api_error: 'Internal Server Error',
  roles_form_unqiue_role_name_error: 'Identifer must be unique',
  roles_form_api_forbidden:
           'Brugeren har ikke tilladelse til at oprette en rolle',
  roles_add_form_success: 'Rollen er oprettet',
  roles_edit_form_success: 'Rollen er opdateret',
  roles_form_add_role_button: 'Tilføj rolle',
  roles_form_edit_role_button: 'Rediger rolle',
  roles_form_cancel_button: 'Annullere',
  roles_form_permissions_title: 'Permissions',
  sidebar_fortes_modules: 'Modules',
  modules_all: 'Alle',
  modules_active: 'Aktive',
  modules_inactive: 'Inaktive',
  modules_filter_delete_button: 'Slet',
  modules_table_identifier: 'Identifier',
  modules_table_name_en: 'Navn (EN)',
  modules_table_name_nl: 'Navn (NL)',
  modules_table_description_en: 'Beskrivelse (EN)',
  modules_table_description_nl: 'Beskrivelse (NL)',
  modules_table_status: 'Status',
  modules_table_updated_at: 'Updated At',
  modules_filter_search: 'Søg',
  modules_no_record_found: 'Ingen enheder fundet',
  modules_table_row_per_page: 'Rækker pr.side',
  modules_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
  modules_deactivated_message: 'Module is deactivated successfully',
  modules_activated_message: 'Module is activated successfully',
  modules_bulk_deactivated_message:
           '##MODULES_COUNT## Modules are deactivated successfully',
  modules_title: 'Modules',
  modules_add_new: 'Tilføj ny',
  modules_bread_crumb_main_title: 'Modules',
  add_modules_bread_crumb_first_link: 'Add Module',
  edit_modules_bread_crumb_first_link: 'Edit Module',
  add_modules_form_header: 'Add Module',
  edit_modules_form_header: 'Edit Module',
  modules_form_module_identifier_field: 'Identifier',
  modules_identifier_required_form_error: 'Identifier is required',
  modules_form_module_name_en_field: 'Navn (EN)',
  modules_form_module_name_nl_field: 'Navn (NL)',
  modules_name_en_required_form_error: 'Navn (EN) er påkrævet',
  modules_name_nl_required_form_error: 'Navn (NL) er påkrævet',
  modules_form_description_en_field: 'Beskrivelse (EN)',
  modules_form_description_nl_field: 'Beskrivelse (NL)',
  modules_form_api_error: 'Internal Server Error',
  modules_form_unqiue_module_name_error: 'Identifer must be unique',
  modules_form_api_forbidden: 'User has no permissions to create module',
  modules_add_form_success: 'Module is created successfully',
  modules_edit_form_success: 'Module is updated successfully',
  modules_form_add_module_button: 'Add Module',
  modules_form_edit_module_button: 'Edit Module',
  modules_form_cancel_button: 'Annullere',
  permissions_all: 'Alle',
  permissions_active: 'Aktive',
  permissions_inactive: 'Inaktive',
  permissions_filter_delete_button: 'Slet',
  permissions_table_identifier: 'Identifier',
  permissions_table_module_name: 'Module',
  permissions_table_name_en: 'Navn (EN)',
  permissions_table_name_nl: 'Navn (NL)',
  permissions_table_description_en: 'Beskrivelse (EN)',
  permissions_table_description_nl: 'Beskrivelse (NL)',
  permissions_table_status: 'Status',
  permissions_filter_search: 'Søg',
  permissions_no_record_found: 'Ingen enheder fundet',
  permissions_table_row_per_page: 'Rækker pr.side',
  permissions_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
  permissions_deactivated_message:
           'Permission is deactivated successfully',
  permissions_activated_message: 'Permission is activated successfully',
  permissions_bulk_deactivated_message:
           '##PERMISSIONS_COUNT## Permissions are deactivated successfully',
  permissions_title: 'Permissions',
  permissions_add_new: 'Tilføj ny',
  permissions_bread_crumb_main_title: 'Permissions',
  add_permissions_bread_crumb_first_link: 'Add Permission',
  edit_permissions_bread_crumb_first_link: 'Edit Permission',
  edit_permissions_form_header: 'Edit Permission',
  add_permissions_form_header: 'Add Permission',
  permissions_form_permission_identifier_field: 'Identifier',
  permissions_form_permission_module_field: 'Module',
  permissions_form_permission_select_module_field: 'Select Module',
  permissions_module_required_form_error: 'Module is required',
  permissions_identifier_required_form_error: 'Identifier is required',
  permissions_form_permission_name_en_field: 'Navn (EN)',
  permissions_form_permission_name_nl_field: 'Navn (NL)',
  permissions_form_permission_sub_module_field: 'Sub Module',
  permissions_form_permission_select_sub_module_field:
           'Select Sub Module',
  permissions_sub_module_required_form_error: 'Sub Module is required',
  permissions_name_required_form_error: 'Navn er påkrævet',
  permissions_form_description_en_field: 'Beskrivelse (EN)',
  permissions_form_description_nl_field: 'Beskrivelse (NL)',
  permissions_form_api_error: 'Internal Server Error',
  permissions_form_unqiue_permission_name_error:
           'Identifer must be unique',
  permissions_edit_form_success: 'Client is updated successfully!',
  permissions_form_api_forbidden:
           'User has no permissions to create permission',
  permissions_add_form_success: 'Permission is created successfully',
  permissions_form_add_permission_button: 'Add Permission',
  permissions_form_edit_permission_button: 'Edit Permission',
  permissions_form_cancel_button: 'Annullere',
  permissions_read: 'Læs',
  permissions_create: 'Opret',
  permissions_update: 'Update',
  permissions_delete: 'Slet',
  permissions_status: 'Toggle Status',
  permissions_get_all: 'Get All',
  permissions_get_by_id: 'Get By Id',
  permissions_bulk_deactivate: 'Bulk De-activate',
  permissions_form_title: 'Permissions',
  duplicate_roles_form_header: 'Duplicate Role',
  duplicate_roles_bread_crumb_first_link: 'Duplicate Role',
  roles_duplicate_form_success: 'Role is duplicated successfully',
  roles_filter_duplicate_button: 'Duplicate Role',
  roles_pre_identifier_required_form_error: 'Pre Identifier is required',
  roles_form_pre_identifier_field: 'Pre Identifier',
  roles_form_pre_identifier_select: 'Select Pre Identifier',
  duplicate_roles_identifier_message:
           '<ul style="margin: "0", padding-left: 15px"> <li>For new client role always start the identifier with client_</li> <li>For new sub client role always start the identifier with service_client_</li> <li>For new fortes role always start the identifier with fortes_</li> </ul>',
  devices_single_device_counters_title: 'Device Counters',
  devices_single_device_counters_offline_counter: 'Offline Counter',
  devices_single_device_counters_online_counter: 'Online Counter',
  devices_single_device_counters_ota_fail_counter: 'OTA Fail Counter',
  devices_single_device_counters_ota_update_counter:
           'OTA Update Counter',
  devices_single_device_counters_mbus_error_counter:
           'MBus error counter',
  devices_single_device_counters_modbus_error_counter:
           'Modbus error counter',
  devices_single_device_docs_directory: 'Directories',
  device_single_documentation_no_documents_found: 'No Documents Found',
  reporting_registers_details_table_registers_pending:
           'Pending Registers',
  reporting_registers_details_table_registers_updated_fail:
           'Failed Registers',
  reporting_registers_details_table_registers_updated_successful:
           'Successful Registers',
  reporting_registers_details_table_status: 'Status',
  reporting_ota_message: 'Update Firmware version to',
  devices_bulk_edit_registers_form_fix_register_values_error:
           'Please fix the errors in all tabs',
  login_permission_error:
           'Please get appropriate permissions from Fortes Administrator',
  monitoring_title: 'Monitoring',
  monitoring_vm_title: 'Virtual Machine',
  monitoring_ram_title: 'RAM',
  monitoring_cpu_title: 'CPU',
  monitoring_disk_title: 'Disk',
  monitoring_restarts_title: 'Restarts',
  monitoring_uptime_title: 'Uptime',
  monitoring_services_title: 'Services',
  monitoring_service_table_service_name: 'Navn',
  monitoring_service_table_service_status: 'Status',
  devices_form_service_client_field: 'Service kunde',
  sidebar_3scale_users: '3Scale Client Users',
  three_scale_users_title: '3Scale Client Users',
  three_scale_users_table_name: 'Navn',
  three_scale_users_table_email: 'Email',
  three_scale_users_table_client: 'Kunde',
  three_scale_users_table_updated_at: 'Updated At',
  three_scale_users_bread_crumb_main_title: '3Scale Client Users',
  add_three_scale_users_bread_crumb_first_link: 'Add 3Scale Client User',
  add_three_scale_users_form_header: 'Add 3Scale Client User',
  three_scale_users_form_name_field: 'Navn',
  three_scale_users_form_email_field: 'Email adresse',
  three_scale_users_form_client_field: 'Kunde',
  three_scale_users_form_password_field: 'Adgangskode',
  three_scale_users_name_required_form_error: 'Navn er påkrævet',
  three_scale_users_email_valid_form_error: 'Skal være en gyldig email',
  three_scale_users_email_required_form_error: 'Email er påkrævet',
  three_scale_users_client_required_form_error: 'Kunde er påkrævet',
  three_scale_users_password_required_form_error:
           'Din adgangskode skal indeholde 8 tegn, mindst et stort bogstav, et tal og et specialtegn.',
  three_scale_users_no_record_found: 'Ingen søgninger fundet',
  three_scale_users_table_row_per_page: 'Rækker pr.side',
  three_scale_users_add_new: 'Add 3Scale Client User',
  three_scale_users_filter_search: 'Søg',
  three_scale_user_single_first_bread_crumb: '3Scale Client Users',
  three_scale_user_single_second_bread_crumb: 'View 3Scale Client Users',
  three_scale_user_single_apis_tab: 'API Keys',
  three_scale_user_single_edit_button_label: 'Rediger',
  three_scale_user_single_user_label: 'Bruger',
  three_scale_user_single_email_label: 'Email',
  three_scale_user_single_client_label: 'Kunde',
  edit_three_scale_users_form_header: 'Edit 3Scale Client User',
  edit_three_scale_users_bread_crumb_first_link:
           'Edit 3Scale Client User',
  device_single_temperature_start_date: 'Start dato',
  device_single_temperature_end_date: 'Slut dato',
  device_single_temperature_start_date_error:
           'Start dato must be less than or equal to Slut dato',
  sidebar_3scale_admins: '3Scale Admin Users',
  three_scale_user_admins_title: '3Scale Admin Users',
  three_scale_user_admins_table_name: 'Navn',
  three_scale_user_admins_table_email: 'Email',
  three_scale_user_admins_table_updated_at: 'Updated At',
  three_scale_user_admins_bread_crumb_main_title: '3Scale Admin Users',
  add_three_scale_user_admins_bread_crumb_first_link:
           'Add 3Scale Admin User',
  add_three_scale_user_admins_form_header: 'Add 3Scale Admin User',
  three_scale_user_admins_form_name_field: 'Navn',
  three_scale_user_admins_form_email_field: 'Email adresse',
  three_scale_user_admins_form_client_field: 'Administrator',
  three_scale_user_admins_form_password_field: 'Adgangskode',
  three_scale_user_admins_name_required_form_error: 'Navn er påkrævet',
  three_scale_user_admins_email_valid_form_error:
           'Skal være en gyldig email',
  three_scale_user_admins_email_required_form_error: 'Email er påkrævet',
  three_scale_user_admins_password_required_form_error:
           'Din adgangskode skal indeholde 8 tegn, mindst et stort bogstav, et tal og et specialtegn.',
  three_scale_user_admins_no_record_found: 'Ingen søgninger fundet',
  three_scale_user_admins_table_row_per_page: 'Rækker pr.side',
  three_scale_user_admins_add_new: 'Add 3Scale Admin User',
  three_scale_user_admins_filter_search: 'Søg',
  three_scale_user_admin_single_first_bread_crumb: '3Scale Admin Users',
  three_scale_user_admin_single_second_bread_crumb:
           'View 3Scale Admin Users',
  three_scale_user_admin_single_apis_tab: 'API Keys',
  three_scale_user_admin_single_edit_button_label: 'Rediger',
  three_scale_user_admin_single_user_label: 'Bruger',
  three_scale_user_admin_single_email_label: 'Email',
  edit_three_scale_user_admins_form_header: 'Edit 3Scale Admin User',
  edit_three_scale_user_admins_bread_crumb_first_link:
           'Edit 3Scale Admin User',
  projects_project_order_number_required_form_error:
           'Project Ordrer nummer is required',
  projects_form_project_order_number_field: 'Project Ordrer nummer',
  client_error_single_device_address: 'Installations adresse',
  navbar_notification_mark_all_as_read: 'Mark all as read',
  navbar_notification_view_all: 'View All',
  notifications_title: 'Notifications',
  notifications_all: 'Alle',
  notifications_unread: 'Unread',
  notifications_read: 'Read',
  notifications_table_title: 'Title (EN)',
  notifications_table_description: 'Beskrivelse (EN)',
  notifications_table_notification_type: 'Notification Type',
  notifications_table_notification_category: 'Notification Category',
  notifications_table_date: 'Dato',
  notifications_filter_search: 'Søg',
  notifications_no_record_found: 'Ingen søgninger fundet',
  notifications_table_row_per_page: 'Rækker pr.side',
  add_notifications_form_header: 'Add Notification',
  notifications_title_en_required_form_error: 'Title (EN) is required',
  notifications_title_nl_required_form_error: 'Title (NL) is required',
  notifications_description_en_required_form_error:
           'Beskrivelse (EN) is required',
  notifications_description_nl_required_form_error:
           'Beskrivelse (NL) is required',
  notifications_form_notification_type_field: 'Notification Type',
  notifications_notification_type_required_form_error:
           'Notification Type is required',
  notifications_form_notification_category_field:
           'Notification Category',
  notifications_notification_category_required_form_error:
           'Notification Kategori er påkrævet',
  notifications_form_title_en_field: 'Title (EN)',
  notifications_form_title_nl_field: 'Title (NL)',
  notifications_form_start_date_field: 'Start dato',
  notifications_form_end_date_field: 'Slut dato',
  notifications_form_description_en_field: 'Beskrivelse (EN)',
  notifications_form_description_nl_field: 'Beskrivelse (NL)',
  notifications_form_add_button: 'Add Notification',
  notifications_form_edit_button: 'Edit Notification',
  notifications_form_cancel_button: 'Annullere',
  notifications_add_form_success: 'Notification is added successfully',
  notifications_form_api_error: 'Internal Server Error',
  notification_type_information: 'Information',
  notification_type_warning: 'Warning',
  notification_category_simple: 'Simple',
  notification_category_timebased: 'Timebased',
  edit_notifications_form_header: 'Edit Notification',
  sidebar_notification_menu: 'Notifications',
  pop_over_close_button: 'Close',
  docs_user_type_end_user: 'End User',
  docs_user_type_installer: 'Installer',
  devices_single_device_docs_no_directory_found: 'No Directories Found',
  device_single_status_tab: 'Visuals',
  device_single_status_picture_tab: 'Picture',
  device_single_status_principle_tab: 'Principle',
  device_single_status_table_loading: 'Loading ...',
  device_single_status_mbus_export_button: 'Eksporter',
  device_single_status_mbus_date_range_filter_label: 'Dato interval',
  device_single_status_mbus_date_export_label: 'Dato',
  device_single_status_mbus_time_export_label: 'Time',
  device_single_status_mbus_port_export_label: 'Port',
  device_single_status_mbus_meter_connected_at_export_label:
           'Meter Connected At',
  device_single_status_mbus_meter_status_export_label: 'Meter Status',
  device_single_status_mbus_meter_type_export_label: 'Meter Type',
  device_single_status_mbus_select_meter_type_export_label:
           'Select Meter Type',
  device_single_status_mbus_select_meter_type_error:
           'Meter Type is missing',
  device_single_status_mbus_export_internal_server_error:
           'Internal Server Error',
  device_single_status_mbus_export_no_data_found_error:
           'Ingen enheder fundet',
  sidebar_diagnostic_formulas: 'Diagnostic Formulas',
  diagnostic_formulas_all: 'All',
  diagnostic_formulas_active: 'Active',
  diagnostic_formulas_inactive: 'InActive',
  diagnostic_formulas_filter_delete_button: 'Delete',
  diagnostic_formulas_table_identifier: 'Identifier',
  diagnostic_formulas_table_name_en: 'Navn (EN)',
  diagnostic_formulas_table_name_nl: 'Navn (NL)',
  diagnostic_formulas_table_description_en: 'Beskrivelse (EN)',
  diagnostic_formulas_table_description_nl: 'Beskrivelse (NL)',
  diagnostic_formulas_table_formula: 'Formula',
  diagnostic_formulas_table_status: 'Status',
  diagnostic_formulas_table_updated_at: 'Updated At',
  diagnostic_formulas_filter_search: 'Søg',
  diagnostic_formulas_no_record_found: 'Ingen enheder fundet',
  diagnostic_formulas_table_row_per_page: 'Rækker pr.side',
  diagnostic_formulas_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
  diagnostic_formulas_deactivated_message:
           'Diagnostic is deactivated successfully',
  diagnostic_formulas_activated_message:
           'Diagnostic is activated successfully',
  diagnostic_formulas_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Diagnostics are deactivated successfully',
  diagnostic_formulas_title: 'Diagnostics Formulas',
  diagnostic_formulas_add_new: 'Tilføj ny',
  diagnostic_formulas_bread_crumb_main_title: 'Diagnostic Formulas',
  add_diagnostic_formulas_bread_crumb_first_link:
           'Add Diagnostic Formulas',
  edit_diagnostic_formulas_bread_crumb_first_link:
           'Edit Diagnostic Formulas',
  add_diagnostic_formulas_form_header: 'Add Diagnostic Formulas',
  edit_diagnostic_formulas_form_header: 'Edit Diagnostic Formulas',
  diagnostic_formulas_form_diagnostic_formulas_identifier_field:
           'Identifier',
  diagnostic_formulas_identifier_required_form_error:
           'Identifier is required',
  diagnostic_formulas_form_diagnostic_formulas_name_en_field:
           'Navn (EN)',
  diagnostic_formulas_form_diagnostic_formulas_name_nl_field:
           'Navn (NL)',
  diagnostic_formulas_name_en_required_form_error:
           'Navn (EN) er påkrævet',
  diagnostic_formulas_name_nl_required_form_error:
           'Navn (NL) er påkrævet',
  diagnostic_formulas_formula_required_form_error: 'Formula is required',
  diagnostic_formulas_form_description_en_field: 'Beskrivelse (EN)',
  diagnostic_formulas_form_description_nl_field: 'Beskrivelse (NL)',
  diagnostic_formulas_form_forumla_field: 'Formula',
  diagnostic_formulas_form_api_error: 'Internal Server Error',
  diagnostic_formulas_form_unqiue_diagnostic_formulas_name_error:
           'Identifer must be unique',
  diagnostic_formulas_form_api_forbidden:
           'User has no permissions to create diagnostic_formulas',
  diagnostic_formulas_add_form_success:
           'Diagnostic is created successfully',
  diagnostic_formulas_edit_form_success:
           'Diagnostic is updated successfully',
  diagnostic_formulas_form_add_diagnostic_formulas_button:
           'Add Diagnostic',
  diagnostic_formulas_form_edit_diagnostic_formulas_button:
           'Edit Diagnostic',
  diagnostic_formulas_form_cancel_button: 'Cancel',
  sidebar_diagnostic_categories: 'Diagnostic Categories',
  diagnostic_categories_all: 'Alle',
  diagnostic_categories_active: 'Aktive',
  diagnostic_categories_inactive: 'Inaktive',
  diagnostic_categories_filter_delete_button: 'Slet',
  diagnostic_categories_table_identifier: 'Identifier',
  diagnostic_categories_table_name_en: 'Navn (EN)',
  diagnostic_categories_table_name_nl: 'Navn (NL)',
  diagnostic_categories_table_description_en: 'Beskrivelse (EN)',
  diagnostic_categories_table_description_nl: 'Beskrivelse (NL)',
  diagnostic_categories_table_status: 'Status',
  diagnostic_categories_table_updated_at: 'Updated At',
  diagnostic_categories_filter_search: 'Søg',
  diagnostic_categories_no_record_found: 'Ingen enheder fundet',
  diagnostic_categories_table_row_per_page: 'Rækker pr.side',
  diagnostic_categories_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
  diagnostic_categories_deactivated_message:
           'Category is deactivated successfully',
  diagnostic_categories_activated_message:
           'Category is activated successfully',
  diagnostic_categories_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Categories are deactivated successfully',
  diagnostic_categories_title: 'Diagnostic Categories',
  diagnostic_categories_add_new: 'Tilføj ny',
  diagnostic_categories_bread_crumb_main_title: 'Diagnostic Categories',
  add_diagnostic_categories_bread_crumb_first_link:
           'Add Diagnostic Category',
  edit_diagnostic_categories_bread_crumb_first_link:
           'Edit Diagnostic Category',
  add_diagnostic_categories_form_header: 'Add Diagnostic Category',
  edit_diagnostic_categories_form_header: 'Edit Diagnostic Category',
  diagnostic_categories_form_diagnostic_categories_identifier_field:
           'Identifier',
  diagnostic_categories_identifier_required_form_error:
           'Identifier is required',
  diagnostic_categories_form_diagnostic_categories_name_en_field:
           'Navn (EN)',
  diagnostic_categories_form_diagnostic_categories_name_nl_field:
           'Navn (NL)',
  diagnostic_categories_name_en_required_form_error:
           'Navn (EN) er påkrævet',
  diagnostic_categories_name_nl_required_form_error:
           'Navn (NL) er påkrævet',
  diagnostic_categories_form_description_en_field: 'Beskrivelse (EN)',
  diagnostic_categories_form_description_nl_field: 'Beskrivelse (NL)',
  diagnostic_categories_form_api_error: 'Internal Server Error',
  diagnostic_categories_form_unqiue_diagnostic_categories_name_error:
           'Identifer must be unique',
  diagnostic_categories_form_api_forbidden:
           'User has no permissions to create diagnostic_categories',
  diagnostic_categories_add_form_success:
           'Category is created successfully',
  diagnostic_categories_edit_form_success:
           'Category is updated successfully',
  diagnostic_categories_form_add_diagnostic_categories_button:
           'Add Category',
  diagnostic_categories_form_edit_diagnostic_categories_button:
           'Edit Category',
  diagnostic_categories_form_cancel_button: 'Cancel',
  sidebar_diagnostics_overview: 'Diagnostics',
  sidebar_firmware_menu: 'Firmware',
  firmwares_all: 'Alle',
  firmwares_active: 'Aktive',
  firmwares_inactive: 'Inaktive',
  firmwares_table_identifier: 'Identifier',
  firmwares_table_name_en: 'Navn (EN)',
  firmwares_table_name_nl: 'Navn (NL)',
  firmwares_table_firmware: 'Firmware',
  firmwares_table_status: 'Status',
  firmwares_table_updated_at: 'Updated At',
  firmwares_filter_search: 'Søg',
  firmwares_no_record_found: 'Ingen enheder fundet',
  firmwares_table_row_per_page: 'Rækker pr.side',
  firmwares_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
  firmwares_deactivated_message: 'Firmware is deactivated successfully',
  firmwares_activated_message: 'Firmware is activated successfully',
  firmwares_title: 'Firmware',
  firmwares_add_new: 'Tilføj ny',
  firmwares_bread_crumb_main_title: 'Firmware',
  add_firmwares_bread_crumb_first_link: 'Add Firmware',
  edit_firmwares_bread_crumb_first_link: 'Edit Firmware',
  add_firmwares_form_header: 'Add Firmware',
  edit_firmwares_form_header: 'Edit Firmware',
  firmwares_form_firmware_identifier_field: 'Identifier',
  firmwares_identifier_required_form_error: 'Identifier is required',
  firmwares_form_firmware_name_en_field: 'Navn (EN)',
  firmwares_form_firmware_name_nl_field: 'Navn (NL)',
  firmwares_form_firmware_firmware_field: 'Firmware',
  firmwares_name_en_required_form_error: 'Navn (EN) er påkrævet',
  firmwares_name_nl_required_form_error: 'Navn (NL) er påkrævet',
  firmwares_form_description_en_field: 'Beskrivelse (EN)',
  firmwares_form_description_nl_field: 'Beskrivelse (NL)',
  firmwares_form_api_error: 'Internal Server Error',
  firmwares_form_unqiue_firmware_name_error: 'Identifer must be unique',
  firmwares_form_api_forbidden:
           'User has no permissions to create firmware',
  firmwares_add_form_success: 'Firmware is created successfully',
  firmwares_edit_form_success: 'Firmware is updated successfully',
  firmwares_form_edit_firmware_button: 'Edit Firmware',
  firmwares_form_cancel_button: 'Annullere',
  diagnostic_flows_all: 'Alle',
  diagnostic_flows_active: 'Aktive',
  diagnostic_flows_inactive: 'Inaktive',
  diagnostic_flows_filter_delete_button: 'Slet',
  diagnostic_flows_table_identifier: 'Identifier',
  diagnostic_flows_table_category: 'Category',
  diagnostic_flows_table_name_en: 'Navn (EN)',
  diagnostic_flows_table_name_nl: 'Navn (NL)',
  diagnostic_flows_table_description_en: 'Beskrivelse (EN)',
  diagnostic_flows_table_description_nl: 'Beskrivelse (NL)',
  diagnostic_flows_table_status: 'Status',
  diagnostic_flows_table_updated_at: 'Updated At',
  diagnostic_flows_filter_search: 'Søg',
  diagnostic_flows_no_record_found: 'Ingen enheder fundet',
  diagnostic_flows_table_row_per_page: 'Rækker pr.side',
  diagnostic_flows_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
  diagnostic_flows_deactivated_message:
           'Flow is deactivated successfully',
  diagnostic_flows_activated_message: 'Flow is activated successfully',
  diagnostic_flows_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Flows are deactivated successfully',
  diagnostic_flows_title: 'Diagnostic Flows',
  diagnostic_flows_add_new: 'Tilføj ny',
  diagnostic_flows_bread_crumb_main_title: 'Diagnostic Flows',
  add_diagnostic_flows_bread_crumb_first_link: 'Add Diagnostic Flow',
  edit_diagnostic_flows_bread_crumb_first_link: 'Edit Diagnostic Flow',
  add_diagnostic_flows_form_header: 'Add Diagnostic Flow',
  edit_diagnostic_flows_form_header: 'Edit Diagnostic Flow',
  diagnostic_flows_form_diagnostic_flows_identifier_field: 'Identifier',
  diagnostic_flows_identifier_required_form_error:
           'Identifier is required',
  diagnostic_flows_form_diagnostic_flows_name_en_field: 'Navn (EN)',
  diagnostic_flows_form_diagnostic_flows_name_nl_field: 'Navn (NL)',
  diagnostic_flows_name_en_required_form_error: 'Navn (EN) er påkrævet',
  diagnostic_flows_name_nl_required_form_error: 'Navn (NL) er påkrævet',
  diagnostic_flows_category_required_form_error: 'Kategori er påkrævet',
  diagnostic_flows_form_description_en_field: 'Beskrivelse (EN)',
  diagnostic_flows_form_description_nl_field: 'Beskrivelse (NL)',
  diagnostic_flows_form_flow_condition_error_field:
           'Condition must have true and false rules',
  diagnostic_flows_form_flow_modal_title_field: 'Er du sikker?',
  diagnostic_flows_form_flow_modal_subtitle_field:
           'You want to save the flow changes in browser?',
  diagnostic_flows_form_flow_modal_cancel_button_field: 'Annullere',
  diagnostic_flows_form_flow_modal_save_button_field: 'Save in Browser',
  diagnostic_flows_form_flow_modal_save_success_message:
           'Flow is successfully saved in Browser',
  diagnostic_flows_form_featured_image_field: 'Featured Image',
  diagnostic_flows_form_upload_featured_image_label:
           'Upload Featured Image',
  diagnostic_flows_form_thumbnail_field: 'Thumbnail',
  diagnostic_flows_form_upload_thumbnail_image_label:
           'Upload Thumbnail Image',
  diagnostic_flows_form_api_error: 'Internal Server Error',
  diagnostic_flows_form_unqiue_diagnostic_flows_name_error:
           'Identifer must be unique',
  diagnostic_flows_form_api_forbidden:
           'User has no permissions to create diagnostic_flows',
  diagnostic_flows_add_form_success:
           'Diagnostic Flow is created successfully',
  diagnostic_flows_edit_form_success:
           'Diagnostic Flow is updated successfully',
  diagnostic_flows_form_add_diagnostic_flows_button: 'Add Flow',
  diagnostic_flows_form_edit_diagnostic_flows_button: 'Edit Flow',
  diagnostic_flows_form_cancel_button: 'Annullere',
  audits_select_event_type_error_option: 'Fejl',
  diagnostic_flows_form_flow_field: 'Flow',
  diagnostic_flows_form_flow_condition_button: 'Condition',
  diagnostic_flows_form_flow_timer_button: 'Timer',
  diagnostic_flows_form_flow_normal_node_button: 'Node',
  diagnostic_flows_form_flow_download_image_button: 'Download Image',
  diagnostic_flows_form_flow_start_button: 'Start',
  diagnostic_flows_form_flow_end_button: 'End',
  devices_bulk_export_mbus_historic_data_button: 'Download Mbus Data',
  devices_bulk_export_mbus_historic_data_file_name: 'Mbus Historic Data',
  devices_bulk_mbus_download_title: 'Download Mbus Historical Data',
  devices_bulk_bulk_mbus_download_form_date_range: 'Dato interval:',
  devices_bulk_bulk_mbus_download_form_submit_button:
           'Download Mbus Data',
  devices_bulk_bulk_mbus_download_form_cancel_button: 'Annullere',
  devices_bulk_mbus_download_form_success:
           'Mbus Historical Data is emailed to ##EMAIL## successfully!',
  devices_bulk_mbus_download_form_api_error: 'Internal Server Error',
  devices_bulk_mbus_download_no_devices_error: '',
  devices_bulk_mbus_download_form_no_devices_error:
           'Please select devices to proceed.',
  devices_bulk_bulk_mbus_download_modal_title: 'Er du sikker?',
  devices_bulk_mbus_download_modal_subtitle:
           'You want to you want to run a bulk download historical Mbus data?',
  devices_bulk_mbus_download_modal_cancel_button: 'Annullere',
  devices_bulk_mbus_download_modal_submit_button: 'Bekræft',
  projects_bulk_export_mbus_historic_data_button: 'Download Mbus Data',
  projects_bulk_export_mbus_historic_data_file_name:
           'Mbus Historic Data',
  projects_bulk_mbus_download_title: 'Download Mbus Historical Data',
  projects_bulk_bulk_mbus_download_form_date_range: 'Dato interval:',
  projects_bulk_bulk_mbus_download_form_data_export_type:
           'Date Export Type',
  projects_bulk_bulk_mbus_download_form_data_export_type_combined:
           'Combined',
  projects_bulk_bulk_mbus_download_form_data_export_type_daily: 'Daily',
  projects_bulk_bulk_mbus_download_form_data_export_type_normal:
           'Normal',
  projects_bulk_bulk_mbus_download_form_submit_button:
           'Download Mbus Data',
  projects_bulk_bulk_mbus_download_form_cancel_button: 'Annullere',
  projects_bulk_mbus_download_form_success:
           'Mbus Historical Data is emailed to ##EMAIL## successfully!',
  projects_bulk_mbus_download_form_api_error: 'Internal Server Error',
  projects_bulk_mbus_download_no_projects_error: '',
  projects_bulk_mbus_download_form_no_projects_error:
           'Please select projects to proceed.',
  projects_bulk_bulk_mbus_download_modal_title: 'Er du sikker?',
  projects_bulk_mbus_download_modal_subtitle:
           'You want to you want to run a bulk download historical Mbus data?',
  projects_bulk_mbus_download_modal_cancel_button: 'Annullere',
  projects_bulk_mbus_download_modal_submit_button: 'Bekræft',
  projects_bulk_mbus_selected_projects: 'Projects Selected',
  sidebar_diagnostic_flows: 'Diagnostic Flows',
  diagnostic_flows_form_diagnostic_flows_category_field:
           'Diagnostic Flow Category',
  diagnostics_send_message_modal_registers_label: 'Registers',
  diagnostics_send_message_modal_operator_label: 'Operator',
  diagnostics_send_message_modal_value_label: 'Value',
  diagnostics_send_message_modal_registers_label_default_option:
           'Select Register',
  diagnostics_send_message_modal_save_button: 'Gem',
  diagnostics_send_message_modal_cancel_button: 'Annullere',
  diagnostics_condition_modal_sensor_type_label: 'Sensor Type',
  diagnostics_condition_modal_modbus_label: 'Modbus Sensor',
  diagnostics_condition_modal_mbus_label: 'Mbus Sensor',
  diagnostics_condition_modal_formula_output_label: 'Formula Output',
  diagnostics_condition_modal_meter_type_label: 'Meter Type',
  diagnostics_condition_modal_meter_type_label_default_option:
           'Select Meter Type',
  diagnostics_condition_modal_operator_label: 'Operator',
  diagnostics_condition_modal_operator_option_1: 'Less',
  diagnostics_condition_modal_operator_option_2: 'Less than equal to',
  diagnostics_condition_modal_operator_option_3: 'Equal to',
  diagnostics_condition_modal_operator_option_4: 'Greater',
  diagnostics_condition_modal_operator_option_5: 'Greater than equal to',
  diagnostics_condition_modal_operator_option_6: 'Not Equal to',
  diagnostics_condition_modal_register_label: 'Register',
  diagnostics_condition_modal_value_label: 'Value',
  diagnostics_condition_modal_timer_label: 'Timer',
  diagnostics_condition_modal_retries_label: 'Retries',
  diagnostics_condition_modal_delay_label: 'Delay',
  diagnostics_condition_modal_times_label: 'times',
  diagnostics_condition_modal_second_label: 'sec',
  diagnostics_condition_modal_save_button: 'Gem',
  diagnostics_condition_modal_cancel_button: 'Annullere',
  extra_white_space_form_error: 'Extra White space is not allowed',
  diagnostic_flows_form_incomplete_flow_error:
           'Please double check edges and the nodes with black color and red animation',
  diagnostic_flows_menu_information: 'Information',
  diagnostic_flows_menu_modbus: 'Modbus',
  diagnostic_flows_menu_message: 'Besked',
  diagnostic_flows_menu_condition: 'Condition',
  diagnostic_flows_menu_user_interaction: 'User Interaction',
  diagnostic_flows_menu_steps: 'Side',
  diagnostic_flows_menu_timer: 'Timer',
  diagnostic_flows_menu_formula: 'Formula',
  diagnostic_flows_form_user_interaction_operation_type:
           'Operation Type',
  diagnostic_flows_form_user_interaction_operation_type_default_option:
           'Select Operation Type',
  diagnostic_flows_form_user_interaction_operation_type_option_choose_mbus_meter:
           'Choose Mbus Meter',
  diagnostic_flows_form_user_interaction_operation_type_option_choose_faucet:
           'Choose Faucet Register',
  diagnostic_flows_form_user_interaction_field_type_option_select_field:
           'Select Field Type',
  diagnostic_flows_form_user_interaction_field_type: 'Field Type',
  diagnostic_flows_form_user_interaction_field_type_default_option:
           'Select Field Type',
  diagnostic_flows_form_user_interaction_field_type_option_checkbox:
           'Checkbox',
  diagnostic_flows_form_user_interaction_field_type_option_radio_button:
           'Radio Button',
  diagnostic_flows_form_user_interaction_field_type_option_text_field:
           'Text Field',
  diagnostic_flows_form_user_interaction_description_en:
           'Beskrivelse (EN)',
  diagnostic_flows_form_user_interaction_description_nl:
           'Beskrivelse (NL)',
  diagnostic_flows_form_steps_label_en: 'Beskrivelse (EN)',
  diagnostic_flows_form_steps_label_nl: 'Beskrivelse (NL)',
  diagnostic_formulas_form_diagnostic_formulas_retries_field: 'Retries',
  diagnostic_formulas_form_diagnostic_formulas_delay_field: 'Forsinket',
  diagnostic_formulas_form_diagnostic_formulas_unit_field:
           'Formula Unit',
  diagnostic_formulas_form_forumla_field_placeholder:
           'Please choose @ to select Modbus or Mbus Registers',
  device_single_diagnostic_flow_missing_pages_error:
           '##SELECTED_FLOW## has no pages. Please add pages in the flow to proceed.',
  device_single_stepper_choose_flow_title: 'Choose the flow',
  device_single_stepper_choose_explanation_of_flow_wizard_title:
           'Explanation of the wizard',
  device_single_stepper_result_title: 'Resultat',
  diagnostic_flows_form_delete_node_modal_title: 'Er du sikker?',
  diagnostic_flows_form_delete_node_modal_subtitle:
           'You want to you want to delete ##NODE_LABEL## node?',
  diagnostic_flows_form_delete_node_modal_cancel_button: 'Annullere',
  diagnostic_flows_form_delete_node_modal_delete_button: 'Slet',
  diagnostic_flows_form_loading_text: 'Loading ...',
  diagnostic_flows_form_processing_text: 'Processing',
  diagnostic_flows_form_main_error_text: 'Flow is stopped due to error',
  diagnostic_flows_form_main_success_text:
           'Flow has completed successfully',
  devices_bulk_bulk_mbus_download_form_data_type: 'Data Type',
  devices_bulk_bulk_mbus_download_form_export_type: 'Export Type',
  devices_bulk_bulk_mbus_download_form_data_type_daily: 'Daglig',
  devices_bulk_bulk_mbus_download_form_data_type_hourly: 'Hourly',
  devices_bulk_bulk_mbus_download_form_export_type_combined: 'Combined',
  devices_bulk_bulk_mbus_download_form_export_type_separated:
           'Separated',
  diagnostic_flows_form_main_stopped_text: 'Flow is stopped by the user',
  diagnostic_execution_title: 'Diagnostic Executions',
  diagnostic_execution_table_created_at: 'Created At',
  diagnostic_execution_table_device: 'Enhed',
  diagnostic_execution_table_user: 'Bruger',
  diagnostic_execution_table_flow: 'Flow',
  diagnostic_execution_table_status: 'Status',
  diagnostic_execution_table_filter_search: 'Søg',
  diagnostic_executions_tab_all: 'Alle',
  diagnostic_executions_tab_completed: 'Completed',
  diagnostic_executions_tab_in_progress: 'In Progress',
  diagnostic_executions_tab_stopped: 'Stopped',
  diagnostic_executions_tab_error: 'Fejl',
  diagnostic_executions_table_no_record_found: 'Ingen søgninger fundet',
  diagnostic_executions_table_row_per_page: 'Rækker pr.side',
  diagnostic_flows_menu_result: 'Resultat',
  diagnostic_flows_menu_result_form_description_en: 'Beskrivelse (EN)',
  diagnostic_flows_menu_result_form_description_nl: 'Beskrivelse (NL)',
  diagnostic_flows_menu_result_form_cancel_button: 'Annullere',
  diagnostic_flows_menu_result_form_save_button: 'Gem',
  diagnostic_flows_menu_page_form_cancel_button: 'Annullere',
  diagnostic_flows_menu_page_form_save_button: 'Gem',
  diagnostic_flows_menu_information_form_description_en:
           'Beskrivelse (EN)',
  diagnostic_flows_menu_information_form_description_nl:
           'Beskrivelse (NL)',
  diagnostic_flows_menu_information_form_cancel_button: 'Annullere',
  diagnostic_flows_menu_information_form_save_button: 'Gem',
  sidebar_diagnostic_execution_title: 'Diagnostic Executions',
  diagnostic_flow_execution_single_first_bread_crumb:
           'Diagnostic Executions',
  diagnostic_flow_execution_single_second_bread_crumb:
           'View Diagnostic Execution',
  diagnostic_flow_execution_single_output_tab: 'Output',
  diagnostic_flow_execution_single_device_name: 'Enhed',
  diagnostic_flow_execution_single_flow: 'Flow',
  diagnostic_flow_execution_single_user: 'Bruger',
  diagnostic_flow_execution_single_status: 'Status',
  diagnostic_flow_execution_single_created_at: 'Created At',
  diagnostic_flow_execution_single_bulk_export_file_name:
           'Bulk Diagnostic Executions',
  diagnostic_flow_execution_single_bulk_export_date: 'Dato',
  diagnostic_flow_execution_single_bulk_export_time: 'Tid',
  diagnostic_flow_execution_single_bulk_export_device_name: 'Enhedsnavn',
  diagnostic_flow_execution_single_bulk_export_device_serial_number_mercurius:
           'Mercurius serienummer',
  diagnostic_flow_execution_single_bulk_export_device_user_full_name:
           'Bruger',
  diagnostic_flow_execution_single_bulk_export_device_flow_name_en:
           'Flow Navn (EN)',
  diagnostic_flow_execution_single_bulk_export_device_flow_name_nl:
           'Flow Navn (NL)',
  diagnostic_flow_execution_single_bulk_export_device_status: 'Status',
  diagnostic_flows_form_main_flow_already_running_error_text:
           'A flow is already running on this device. Multiple flow executions are not allowed',
  diagnostic_flows_form_open_faucet_on_option: 'On',
  diagnostic_flows_form_open_faucet_off_option:
           'Off (Flow will go in error mode)',
  diagnostic_flows_form_mbus_meters_error:
           'Device does not have flow required Mbus Meters',
  diagnostic_flows_result_title: 'Result',
  duplicate_diagnostic_flows_form_header: 'Duplicate Diagnostic Flow',
  duplicate_diagnostic_flows_bread_crumb_first_link:
           'Duplicate Diagnostic Flow',
  diagnostic_flows_form_duplicate_diagnostic_flows_button: 'Duplicate',
  diagnostic_flows_duplicate_form_success:
           'Diagnostic Flow is duplicated successfully',
  diagnostic_flows_filter_duplicate_button: 'Duplicate Flow',
  diagnostic_flows_menu_result_form_id: 'Id',
  audits_street_number_filter_label: 'Gade',
  audits_house_number_filter_label: 'Husnummer',
  diagnostic_execution_counter_title: 'Diagnostic Execution Counters',
  diagnostic_execution_counter_table_id: 'Id',
  diagnostic_execution_counter_table_counter: 'Counters',
  diagnostic_execution_counter_table_flow: 'Flow',
  diagnostic_execution_counter_table_result: 'Resultat',
  diagnostic_execution_counter_table_filter_search: 'Søg',
  diagnostic_execution_counter_table_no_record_found:
           'Ingen søgninger fundet',
  diagnostic_execution_counter_table_row_per_page: 'Rækker pr.side',
  sidebar_diagnostic_flow_execution_counter: 'Diagnostic Flow Counters',
  device_single_firmware_hiu_label: 'Firmware HIU',
  device_single_firmware_mercurius_label: 'Firmware Mercurius',
  custom_popover_105_long_description: 'Alarm Register Long Description',
  settings_menu_title: 'Settings',
  settings_menu_clients_title: 'Clients',
  settings_menu_admins_title: 'Admins',
  settings_menu_3scale_admins_title: '3Scale admins',
  settings_menu_3scale_clients_title: '3Scale clients',
  settings_menu_diagnostics_title: 'Diagnostics',
  settings_menu_formulas_title: 'Formulas',
  settings_menu_flows_title: 'Flows',
  settings_menu_categories_title: 'Categories',
  settings_menu_firmwares_title: 'Firmware',
  settings_menu_appliance_types_title: 'Appliance types',
  settings_menu_notifications_title: 'Notifications',
  settings_menu_roles_title: 'Roller',
  settings_menu_modules_title: 'Modules',
  settings_menu_permissions_title: 'Permissions',
  settings_menu_go_back_button: 'Go Back',
  audits_device_name_filter_label: 'Enhedsnavn',
  device_single_documentation_parts_button: 'Onderdelen',
  device_single_documentation_lab_vision_button: 'Labvision handleiding',
  device_single_documentation_manuals_button: 'Handleiding',
  device_single_documentation_incident_button: 'Storingsleutel',
  navbar_avg_proof_switch: 'Privacy switch',
  clients_filter_bulk_delete_button: 'Slet',
  clients_bulk_delete_no_clients_with_zero_devices_is_selected_error_message:
           'Please select atleast one record with 0 devices',
  clients_bulk_delete_clients_with_devices_count_is_selected_error_message:
           'Please select only those records which have 0 devices',
  projects_filter_bulk_delete_button: 'Delete',
  projects_bulk_delete_no_projects_with_zero_devices_is_selected_error_message:
           'Please select atleast one record with 0 devices',
  projects_bulk_delete_projects_with_devices_count_is_selected_error_message:
           'Please select only those records which have 0 devices',
  projects_bulk_deleted_message:
           '{{ PROJECTS_COUNT }} Projects are deleted successfully',
  projects_delete_modal_title: 'Er du sikker?',
  projects_delete_modal_subtitle: 'You want to delete Records?',
  projects_delete_modal_cancel_button: 'Annullere',
  projects_delete_modal_delete_button: 'Slet',
  users_delete_modal_title: 'Er du sikker?',
  users_delete_modal_subtitle: 'You want to delete Records?',
  users_delete_modal_cancel_button: 'Annullere',
  users_delete_modal_delete_button: 'Slet',
  users_bulk_delete_no_user_is_selected_error_message:
           'Please select records to delete',
  users_bulk_deleted_message: '{{ USERS_COUNT }} Brugeren er slettet',
  service_client_single_filter_bulk_delete_button: 'Slet',
  service_client_single_no_user_selected_for_deleted_message:
           'Please select records to delete',
  admins_bulk_deleted_message:
           '{{ USERS_COUNT }} Administrator er slettet',
  admins_no_user_selected_for_deleted_message:
           'Please select records to deleted',
  admins_filter_bulk_delete_button: 'Slet',
  three_scale_admins_bulk_deleted_message:
           '{{ USERS_COUNT }} Three Administrator er slettet',
  audits_message_type_label: 'Message Type',
  audit_bulk_export_file_name: 'Audits File',
  registers_reporting_details_all_tab: 'All',
  registers_reporting_details_pending_tab: 'Pending',
  registers_reporting_details_success_tab: 'Success',
  registers_reporting_details_failure_tab: 'Fejlet',
  devices_form_is_service_device_field: 'Service Device',
  service_portal_login_in_correct_qr_code:
           'Device Serial Number is incorrect',
  service_portal_login_in_correct_credentials:
           'Device credendtials are incorrect. Please contact Fortes Administrator.',
  service_portal_login_not_a_registered_service_device:
           'Device with Serial Number {{ SERIAL_NUMBER }} is not registered.',
  devices_unprovisioned: 'unprovisioned',
  sidebar_unprovisioned_devices: 'Devices - Unprovisioned',
  dashboard_unprovisioned: 'UNPROVISIONED',
  device_single_documentation_warmwater_button: 'Storing Warm Water',
  device_single_documentation_verwarming_button: 'Storing Verwarming',
  service_portal_login_in_serial_number_scan_button:
           'Scan Serial Number',
  service_portal_login_in_heat_interface_unit_scan_button:
           'Scan Fjernvarmeunit',
  service_portal_login_in_serial_number_scanned_message:
           'Serial Number is scanned',
  service_portal_login_in_heat_interface_unit_scanned_message:
           'Fjernvarmeunit is scanned',
  swapping_device_missing_new_device_error:
           'Ny enhed is not imported in the Mercurius database',
  device_single_dashboard_mbus_title: 'MBus',
  device_single_dashboard_tab: 'Dashboard',
  device_single_offline_detailed_message: 'Enheden er "offline"',
  device_single_online_detailed_message:
           'Enheden er "online", everything is working fine',
  device_single_error_detailed_message: 'Device is in "Error" mode',
  device_single_status_documents_link: 'Device Documents',
  email_device_delete_subscription_title:
           'Fortes Data Management - Delete Subscription',
  email_device_delete_subscription_api_status_title:
           'Maxitel API Response:',
  email_device_delete_subscription_type_swapped_device_message:
           'Device subscription is deleted in Device Swapping module',
  email_device_delete_subscription_type_clear_device_message:
           'Device subscription is deleted in Device Clear module',
  devices_form_subscription_id_field: 'Subscription Id',
  device_single_rss_sim_status: 'SIM Status',
  device_monitoring_error_list_table_title: 'Error List',
  device_monitoring_error_list_table_device: 'Device',
  device_monitoring_error_list_table_alarm_code: 'Alarm Code',
  device_monitoring_error_list_table_start_date: 'Start Date',
  device_monitoring_error_list_table_end_date: 'End Date',
  device_monitoring_data_consumed_list_table_title: 'Data Consumed',
  device_monitoring_data_consumed_list_table_device: 'Device',
  device_monitoring_data_consumed_list_table_data_usage: 'Data Usage',
  device_monitoring_data_consumed_list_table_date: 'Date',
  device_monitoring_ota_history_list_table_title: 'OTA History',
  device_monitoring_ota_history_list_table_device: 'Device',
  device_monitoring_ota_history_list_table_status: 'Status',
  device_monitoring_ota_history_list_table_date: 'Date',
  device_monitoring_device_status_history_title: 'Device Status History',
  device_monitoring_device_status_history_hr: 'Hr',
  device_monitoring_device_status_history_no_graph_data_error:
           'The graph is unavailable',
  device_monitoring_device_status_history_graph_loading_data:
           'Loading graph',
  device_monitoring_firmware_history_title: 'Firmware History',
  device_monitoring_device_firmware_history_graph_loading_data:
           'Loading barchart',
  device_monitoring_device_firmware_history_no_graph_data_error:
           'The barchart is unavailable',
  sidebar_devices_monitoring: 'Devices Monitoring',
  device_monitoring_projects_counter_title: 'Projects Counter',
  device_monitoring_device_projects_counter_graph_loading_data:
           'Loading barchart',
  device_monitoring_device_projects_counter_no_graph_data_error:
           'The barchart is unavailable',
  device_monitoring_users_counter_title: 'Users Counter',
  device_monitoring_device_users_counter_graph_loading_data:
           'Loading barchart',
  device_monitoring_device_users_counter_no_graph_data_error:
           'The barchart is unavailable',
  device_monitoring_sim_counter_title: 'SIM Data Counter',
  device_monitoring_sim_counter_exceeded_data_title: 'Exceeded',
  device_monitoring_sim_counter_in_package_data_title:
           'In Package Limit',
  device_monitoring_device_sim_counter_graph_loading_data:
           'Loading barchart',
  device_monitoring_device_sim_counter_no_graph_data_error:
           'The barchart is unavailable',
  service_portal_login_in_dont_have_hiu_qr_code_checkbox_field:
           "Don't have HIU QR Code",
  service_portal_login_hiu_autocomplete: 'Heat Interface Unit',
  device_monitoring_device_error_counters_title: 'Error Counters',
  device_monitoring_device_error_counters_error: 'No data found',
  device_monitoring_device_error_counters_no_graph_data_error:
           'The piechart is unavailable',
  device_monitoring_date_range_field: 'Date Range',
  reporting_ota_details_retries_tab: 'Retries',
  device_monitoring_single_page_title: 'Device Monitoring',
  device_monitoring_ota_history_list_table_fail_counter: 'Retries',
  device_monitoring_ota_history_list_table_user: 'User',
  device_monitoring_ota_history_list_table_message: 'Message',
  device_monitoring_ota_history_list_table_old_firmware_version:
           'Old Firmware Version',
  device_monitoring_project_list_table_title: 'Projects',
  device_monitoring_firmware_list_table_title: 'Firmwares',
  device_monitoring_firmware_list_table_firmware_version: 'Firmware',
  device_monitoring_firmware_list_table_device: 'Device',
  device_monitoring_firmware_list_table_project: 'Project',
  device_monitoring_firmware_list_table_saved_at: 'Date',
  monitoring_users_list_table_title: 'Users',
  monitoring_3scale_users_list_table_title: 'Three Scale Users',
  monitoring_3scale_users_filter_3scale_admin: '3Scale Admin',
  monitoring_3scale_users_filter_3scale_client: '3Scale Client',
  client_single_users_table_role: 'Role',
  device_monitoring_device_status_list_table_title: 'Device Status',
  navbar_languages_english: 'English',
  navbar_languages_dutch: 'Nederlands',
  navbar_languages_german: 'Deutsch',
  device_single_device_settings_tab: 'Settings v2',
  device_single_device_settings_title: 'Device v2 Settings',
  device_single_device_settings_secondary_device_type_field:
           'Secondary Device Type',
  device_single_device_settings_base_topic_field: 'Base Topic',
  device_single_device_settings_baud_rate_uart_field: 'Baud Rate UART',
  device_single_device_settings_select_baud_rate_uart_field:
           'Select Baud Rate UART',
  device_single_device_settings_modbus_error_register_address_field:
           'Modbus error register address',
  device_single_device_settings_modbus_error_register_value_field:
           'Modbus expected error register value',
  device_single_device_settings_reboot_device_v2: 'Reboot',
  device_single_device_settings_send_button_v2: 'Send',
  device_single_device_settings_message_forwarded:
           'Message is forwarded to device',
  device_single_device_settings_secondary_device_type_error:
           'Secondary Device Type must be between 1 and 254',
  device_single_device_settings_base_topic_error:
           'Base Topic length must be between 5 and 50',
  device_single_device_settings_baud_rate_uart_error:
           'Please select a option for Baud Rate UART',
  device_single_device_settings_modbus_error_register_address_error:
           'Modbus error register address must be between 0 and 65535',
  device_single_device_settings_modbus_error_register_value_error:
           'Modbus expected error register value must be between 0 and 65535',
  device_monitoring_device_error_counters_loading_data:
           'Loading piechart',
  diagnostic_formulas_form_diagnostic_formulas_name_de_field:
           'Name (DE)',
  diagnostic_formulas_name_de_required_form_error:
           'Name (DE) is required',
  diagnostic_formulas_form_description_de_field: 'Description (DE)',
  diagnostic_flows_form_diagnostic_flows_name_de_field: 'Name (DE)',
  diagnostic_flows_name_de_required_form_error: 'Name (DE) is required',
  diagnostic_flows_form_description_de_field: 'Description (DE)',
  diagnostic_categories_form_diagnostic_categories_name_de_field:
           'Name (DE)',
  diagnostic_categories_name_de_required_form_error:
           'Name (DE) is required',
  diagnostic_categories_form_description_de_field: 'Description (DE)',
  diagnostic_categories_table_name_de: 'Name (DE)',
  diagnostic_flows_table_name_de: 'Name (DE)',
  diagnostic_formulas_table_name_de: 'Name (DE)',
  modules_form_module_name_de_field: 'Name (DE)',
  modules_name_de_required_form_error: 'Name (DE) is required',
  modules_form_description_de_field: 'Description (DE)',
  modules_table_name_de: 'Name (DE)',
  modules_table_description_de: 'Description (DE)',
  roles_form_role_name_de_field: 'Name (DE)',
  roles_name_de_required_form_error: 'Name (DE) is required',
  roles_form_description_de_field: 'Description (DE)',
  roles_table_name_de: 'Name (DE)',
  roles_table_description_de: 'Description (DE)',
  permissions_form_permission_name_de_field: 'Name (DE)',
  permissions_form_description_de_field: 'Description (DE)',
  permissions_table_name_de: 'Name (DE)',
  permissions_table_description_de: 'Description (DE)',
  notifications_form_title_de_field: 'Title (DE)',
  notifications_form_description_de_field: 'Description (DE)',
  firmwares_name_de_required_form_error: 'Name (DE)',
  firmwares_form_firmware_name_de_field: 'Name (DE) is required',
  navbar_languages_danish: 'Danish',
  firmwares_name_dk_required_form_error: 'Name (DK)',
  firmwares_form_firmware_name_dk_field: 'Name (DK) is required',
  diagnostic_formulas_form_diagnostic_formulas_name_dk_field:
           'Name (DK)',
  diagnostic_formulas_name_dk_required_form_error:
           'Name (DK) is required',
  diagnostic_formulas_form_description_dk_field: 'Description (DK)',
  diagnostic_flows_form_diagnostic_flows_name_dk_field: 'Name (DK)',
  diagnostic_flows_name_dk_required_form_error: 'Name (DK) is required',
  diagnostic_flows_form_description_dk_field: 'Description (DK)',
  diagnostic_categories_form_diagnostic_categories_name_dk_field:
           'Name (DK)',
  diagnostic_categories_name_dk_required_form_error:
           'Name (DK) is required',
  diagnostic_categories_form_description_dk_field: 'Description (DK)',
  diagnostic_categories_table_name_dk: 'Name (DK)',
  diagnostic_flows_table_name_dk: 'Name (DK)',
  diagnostic_formulas_table_name_dk: 'Name (DK)',
  modules_form_module_name_dk_field: 'Name (DK)',
  modules_name_dk_required_form_error: 'Name (DK) is required',
  modules_form_description_dk_field: 'Description (DK)',
  modules_table_name_dk: 'Name (DK)',
  modules_table_description_dk: 'Description (DK)',
  roles_form_role_name_dk_field: 'Name (DK)',
  roles_name_dk_required_form_error: 'Name (DK) is required',
  roles_form_description_dk_field: 'Description (DK)',
  roles_table_name_dk: 'Name (DK)',
  roles_table_description_dk: 'Description (DK)',
  permissions_form_permission_name_dk_field: 'Name (DK)',
  permissions_form_description_dk_field: 'Description (DK)',
  permissions_table_name_dk: 'Name (DK)',
  permissions_table_description_dk: 'Description (DK)',
  notifications_form_title_dk_field: 'Title (DK)',
  notifications_form_description_dk_field: 'Description (DK)',
  device_monitoring_data_consumed_list_table_project: 'Project',
  project_single_device_list_search_mbus_registers_by_project:
           'Search Mbus Registers',
  project_single_fetch_mbus_registers_by_project_success_message:
           'Message is forwarded to Project ##PROJECT_NAME## Devices successfully',
  project_single_fetch_mbus_registers_by_project_error_message:
           'Interval Server Error',
  devices_list_diagnostic_filters_title: 'Registers Filter',
  devices_list_diagnostics_condition_modal_sensor_type_label:
           'Sensor Type',
  devices_list_diagnostics_condition_modal_modbus_label: 'Modbus Sensor',
  devices_list_diagnostics_condition_modal_mbus_label: 'Mbus Sensor',
  devices_list_diagnostics_condition_modal_formula_output_label:
           'Formula Output',
  devices_list_diagnostics_condition_modal_meter_type_label:
           'Meter Type',
  devices_list_diagnostics_condition_modal_meter_type_label_default_option:
           'Select Meter Type',
  devices_list_diagnostics_condition_modal_operator_label: 'Operator',
  devices_list_diagnostics_condition_modal_operator_option_1: 'Less',
  devices_list_diagnostics_condition_modal_operator_option_2:
           'Less than equal to',
  devices_list_diagnostics_condition_modal_operator_option_3: 'Equal to',
  devices_list_diagnostics_condition_modal_operator_option_4: 'Greater',
  devices_list_diagnostics_condition_modal_operator_option_5:
           'Greater than equal to',
  devices_list_diagnostics_condition_modal_operator_option_6:
           'Not Equal to',
  devices_list_diagnostics_condition_modal_register_label: 'Register',
  devices_list_diagnostics_condition_modal_register_required_error:
           'Register is required',
  devices_list_diagnostics_send_message_modal_registers_label_default_option:
           'Select Register',
  devices_list_diagnostics_condition_modal_value_label: 'Value',
  devices_list_diagnostics_condition_modal_value_is_required_error:
           'Value is required',
  devices_list_diagnostics_condition_modal_operator_is_required_error:
           'Operator is required',
  devices_list_diagnostics_condition_modal_meter_is_required_error:
           'Meter is required',
  devices_list_diagnostics_condition_modal_value_invalid_error:
           'Invalid Value, please check',
  devices_list_diagnostics_condition_modal_filter_button: 'Filter',
  devices_list_diagnostics_condition_modal_close_button: 'Close',
  devices_list_diagnostics_condition_modal_add_button:
           'Add more filters',
  error_check_table_title: 'Device Error Checks',
  error_check_table_filter_search: 'Search',
  error_check_table_no_record_found: 'No Records Found',
  error_check_table_row_per_page: 'Rows Per Page',
  error_check_table_created_at: 'Created At',
  error_check_table_total_devices: 'Total Devices',
  error_check_details_table_title: 'Device Error Check Details',
  error_check_details_table_filter_search: 'Search',
  error_check_details_table_no_record_found: 'No Records Found',
  error_check_details_table_row_per_page: 'Rows Per Page',
  error_check_details_table_created_at: 'Created At',
  error_check_details_table_device: 'Device',
  error_check_details_table_old_error: 'Old Error',
  error_check_details_table_new_error: 'New Error',
  error_check_details_table_status: 'Status',
  error_check_details_table_status_success: 'Success',
  error_check_details_table_status_failed: 'Failure',
  error_check_details_table_all_tab: 'All',
  error_check_details_table_success_tab: 'Success',
  error_check_details_table_failure_tab: 'Failure',
  error_check_details_table_device_name: 'Device Name',
  error_check_details_table_device_serial_number: 'Serial Number',
  sidebar_devices_error_check: 'Error Devices',
  error_check_details_table_bulk_export_file_name:
           'Error Devices Details List',
  error_check_table_bulk_export_file_name: 'Error Devices List',
  device_single_registers_table_min_error:
           'Value must be greater than or equal to min_value',
  device_single_registers_table_invalid_value_error:
           'Please enter a valid number',
  project_single_project_setting_tab: 'Project Setting',
  project_single_project_setting_mbus_registers_title: 'MBus Registers',
  project_single_project_setting_mbus_register_accumulated_heating_energy_title:
           'Accumulated heating energy Unit',
  project_single_project_setting_mbus_register_accumulated_heating_energy_success_message:
           'Accumulated heating energy unit is successfully updated',
  project_single_project_setting_mbus_register_accumulated_heating_energy_error_message:
           'Failed to update Accumulated heating energy unit',
  project_single_project_setting_select_mbus_register_accumulated_heating_energy_title:
           'Select Accumulated heating energy',
  project_single_project_setting_select_mbus_register_update_button:
           'Update',
  custom_popover_100_short_description: 'Varme status',
  custom_popover_101_short_description: 'Varmt vand status',
  custom_popover_102_short_description: 'Tomgang status',
  custom_popover_103_short_description: 'Termisk desinfektion status',
  custom_popover_104_short_description: 'Køling status',
  custom_popover_1802_short_description: 'TE Heating Element State',
  custom_popover_105_short_description: 'Alarm kode',
  custom_popover_106_short_description: 'Software version',
  custom_popover_107_short_description: 'Ventil (M1)',
  custom_popover_108_short_description: 'Ventil (M2)',
  custom_popover_109_short_description: 'Pumpe',
  custom_popover_110_short_description: 'Ikke i brug',
  custom_popover_113_short_description: 'Rum termostat',
  custom_popover_114_short_description: 'OpenTherm Indicator',
  custom_popover_115_short_description: 'Appliance Type',
  custom_popover_200_short_description: 'Varmt vands flow',
  custom_popover_201_short_description: 'Varmt vands temperatur',
  custom_popover_202_short_description: 'Fjernvarme fremløb',
  custom_popover_203_short_description: 'Udendørstemperatur',
  custom_popover_205_short_description: 'Varme fremløb',
  custom_popover_206_short_description: 'Fjernvarme retur',
  custom_popover_204_short_description: 'Fremløb setpunkt',
  custom_popover_207_short_description: 'Rum temperatur (OpenTherm)',
  custom_popover_208_short_description: 'Rum setpoint (OpenTherm)',
  custom_popover_210_short_description: 'Sekundær vandtryk',
  custom_popover_800_short_description: 'Total drifttid',
  custom_popover_801_short_description: 'Varme drifttid',
  custom_popover_802_short_description: 'Køle drifttid',
  custom_popover_803_short_description: 'Varmt vand drifttid',
  custom_popover_804_short_description: 'Antal tap (varmt vand)',
  custom_popover_805_short_description: 'Hot water Volume',
  custom_popover_806_short_description: 'Power cuts',
  create_role_stepper_information_title: 'Information',
  create_role_stepper_client_title: 'Client',
  create_role_stepper_service_client_title: 'Service Client',
  create_role_stepper_project_title: 'Project',
  create_role_stepper_device_management_title: 'Device Management',
  create_role_stepper_commissioning_title: 'Commissioning',
  create_role_stepper_reporting_title: 'Reporting',
  create_role_stepper_general_title: 'General',
  roles_form_role_name_field: 'Navn',
  roles_name_required_form_error: 'Navn er påkrævett',
  roles_form_description_field: 'Beskrivelse',
  create_role_stepper_back_button: 'Back',
  create_role_stepper_next_button: 'Next',
  create_role_stepper_finish_button: 'Finish',
  create_role_stepper_yes_label: 'Yes',
  create_role_stepper_no_label: 'No',
  create_role_stepper_step_2_client_view_all_users: 'View all users?',
  create_role_stepper_step_2_client_create_new_users: 'Create new users?',
  create_role_stepper_step_2_client_edit_new_users: 'Modify existing users like the role?',
  create_role_stepper_step_3_service_client_create_new: 'Create a new \'service client\'?',
  create_role_stepper_step_3_service_client_view_users: 'View existing \'service client\' users?',
  create_role_stepper_step_3_service_client_create_users: 'Create new \'service client\' users?',
  create_role_stepper_step_3_service_client_modify_users: 'Modify \'service client\' users like the role?',
  create_role_stepper_step_4_project_view: 'View projects?',
  create_role_stepper_step_4_project_create_new: 'Create new projects?',
  create_role_stepper_step_4_project_export_mbus_data: 'Export energy meter data from projects?',
  create_role_stepper_step_5_device_view_single_page: 'Open a device?',
  create_role_stepper_step_5_device_view_single_page_setting_registers: 'View settings?',
  create_role_stepper_step_5_device_edit_single_page_setting_registers: 'Modify settings?',
  create_role_stepper_step_5_device_edit_single_page_manual_control: 'Use manual control?',
  create_role_stepper_step_5_device_single_page_view_history: 'View history?',
  create_role_stepper_step_5_device_single_page_view_meter_data: 'View meter data?',
  create_role_stepper_step_5_device_single_page_use_diagnostics_module: 'Use the diagnostics module?',
  create_role_stepper_step_5_device_single_page_information_and_delivery_set_data: 'View information such as device info, address details, and delivery set data?',
  create_role_stepper_step_6_commissioning_module_put_devices_into_operation: 'Commissioning module to put devices into operation?',
  create_role_stepper_step_6_commissioning_module_swapping: 'Exchange devices (transfer data from old device to new device)?',
  create_role_stepper_step_7_reporting_audit: 'The logbook that contains all messages, errors, and actions on devices?',
  create_role_stepper_step_7_reporting_swapped_devices: 'Logbook of exchanged devices?',
  create_role_stepper_step_7_reporting_error_devices: 'Logbook of devices with faults?',
  create_role_stepper_step_7_reporting_bulk_registers_update: 'Results of group changes?',
  create_role_stepper_step_8_view_dashboard: 'Dashboard all access and sidebar device counters',
  create_role_stepper_step_8_general_create_role: 'Creating roles?',
  create_role_stepper_question_required_error: 'One option is required to proceed',
  roles_form_role_name_en_field: 'Navn (EN)',
  roles_form_role_name_nl_field: 'Navn (NL)',
  roles_name_en_required_form_error: 'Navn (EN) er påkrævet',
  roles_name_nl_required_form_error: 'Navn (NL) er påkrævet',
  roles_form_description_en_field: 'Beskrivelse (EN)',
  roles_form_description_nl_field: 'Beskrivelse (NL)',
  project_single_project_setting_device_v2_settings_title: 'Device V2 Settings',
  project_single_device_settings_secondary_device_type_field: 'Secondary Device Type',
  project_single_project_setting_base_topic_field: 'Base Topic',
  project_single_device_settings_baud_rate_uart_field: 'Baud Rate UART',
  project_single_device_settings_select_baud_rate_uart_field: 'Select Baud Rate UART',
  project_single_device_settings_modbus_error_register_address_field: 'Modbus error register address',
  project_single_device_settings_modbus_error_register_value_field: 'Modbus expected error register value',
  project_single_project_setting_device_v2_send_button: 'Send',
  project_single_project_setting_base_topic_success_message:
    'Base Topic is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  project_single_project_setting_secondary_device_type_success_message:
    'Secondary Device Type is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  project_single_project_setting_reboot_message:
    'Device Reboot Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  project_single_project_setting_baut_rate_uart_success_message:
    'Device Baut Rate UART Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  project_single_project_setting_modbus_expected_error_register_value_success_message:
    'Device Modbus Expected Error Register Value Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  project_single_project_setting_modbus_error_register_address_success_message:
    'Device Reboot Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  docs_third_tab_video_instruction: 'Video Instruction',
  docs_third_tab_video_instruction_title: 'Video Instruction',
  custom_popover_106_long_description: 'Software version',
  EXPORT_MBUS_METER_SERIAL_NUMBER: 'Serienummer meter',
  navbar_languages_french: 'French',
  diagnostic_formulas_form_diagnostic_formulas_name_fr_field: 'Name (FR)',
  diagnostic_formulas_name_fr_required_form_error: 'Name (FR) is required',
  diagnostic_formulas_form_description_fr_field: 'Description (FR)',
  diagnostic_flows_form_diagnostic_flows_name_fr_field: 'Name (FR)',
  diagnostic_flows_name_fr_required_form_error: 'Name (FR) is required',
  diagnostic_flows_form_description_fr_field: 'Description (FR)',
  diagnostic_categories_form_diagnostic_categories_name_fr_field: 'Name (FR)',
  diagnostic_categories_name_fr_required_form_error: 'Name (FR) is required',
  diagnostic_categories_form_description_fr_field: 'Description (FR)',
  modules_form_module_name_fr_field: 'Name (FR)',
  modules_name_fr_required_form_error: 'Name (FR) is required',
  modules_form_description_fr_field: 'Description (FR)',
  roles_form_role_name_fr_field: 'Name (FR)',
  roles_name_fr_required_form_error: 'Name (FR) is required',
  roles_form_description_fr_field: 'Description (FR)',
  permissions_form_permission_name_fr_field: 'Name (FR)',
  permissions_form_description_fr_field: 'Description (FR)',
  firmwares_form_firmware_name_fr_field: 'Name (FR)',
  firmwares_name_fr_required_form_error: 'Name (FR) is required',
  notifications_form_title_fr_field: 'Name (FR)',
  notifications_form_description_fr_field: 'Description (FR)',
  notifications_title_dk_required_form_error: 'Name (DK) is required',
  notifications_title_fr_required_form_error: 'Name (FR) is required',
  notifications_title_de_required_form_error: 'Name (DE) is required',
  notifications_description_dk_required_form_error: 'Description (DK) is required',
  notifications_description_de_required_form_error: 'Description (DE) is required',
  notifications_description_fr_required_form_error: 'Description (FR) is required',
  client_single_error_devices_bread_crumb: 'Error Devices',
  service_client_single_error_devices_bread_crumb: 'Error Devices',
  devices_list_diagnostics_condition_modal_fetch_and_filter_button: 'Fetch & Filter',
  diagnostic_register_drawer_selected_devices_title: 'Selected Devices',
  diagnostic_register_drawer_fetch_and_filter_message_1: 'Fetching devices data',
  diagnostic_register_drawer_fetch_and_filter_message_2: 'Database refresh',
  diagnostic_register_drawer_fetch_and_filter_message_3: 'Filtering devices',
  devices_bulk_mbus_download_selected_devices_title: 'Devices Selected',
  custom_popover_100_long_description: 'Varme status',
  custom_popover_101_long_description: 'Varmt vand status',
  custom_popover_102_long_description: 'Tomgang status',
  custom_popover_103_long_description: 'Termisk desinfektion status',
  custom_popover_104_long_description: 'Køling status',
  custom_popover_1802_long_description: 'TE Heating Element State',
  custom_popover_107_long_description: 'Ventil (M1)',
  custom_popover_108_long_description: 'Ventil (M2)',
  custom_popover_109_long_description: 'Pumpe',
  custom_popover_110_long_description: 'Ikke i brug',
  custom_popover_113_long_description: 'Rum termostat',
  custom_popover_114_long_description: 'OpenTherm Indicator',
  custom_popover_115_long_description: 'Appliance Type',
  custom_popover_200_long_description: 'Varmt vands flow',
  custom_popover_201_long_description: 'Varmt vands temperatur',
  custom_popover_202_long_description: 'Fjernvarme fremløb',
  custom_popover_203_long_description: 'Udendørstemperatur',
  custom_popover_205_long_description: 'Varme fremløb',
  custom_popover_206_long_description: 'Fjernvarme retur',
  custom_popover_204_long_description: 'Fremløb setpunkt',
  custom_popover_207_long_description: 'Rum temperatur (OpenTherm)',
  custom_popover_208_long_description: 'Rum setpoint (OpenTherm)',
  custom_popover_210_long_description: 'Sekundær vandtryk',
  custom_popover_800_long_description: 'Total drifttid',
  custom_popover_801_long_description: 'Varme drifttid',
  custom_popover_802_long_description: 'Køle drifttid',
  custom_popover_803_long_description: 'Varmt vand drifttid',
  custom_popover_804_long_description: 'Antal tap (varmt vand)',
  custom_popover_805_long_description: 'Hot water Volume',
  custom_popover_806_long_description: 'Power cuts',
  project_single_project_setting_mbus_hourly_logs_title: 'Mbus Hourly Logs',
  project_single_project_setting_mbus_hourly_logs_field: 'Fetch Mbus Hourly',
  project_single_project_setting_mbus_hourly_logs_update_button: 'Update',
  project_single_project_setting_mbus_hourly_logs_success_message: 'Mbus Hourly logs are successfully set for the project',
  project_single_project_setting_mbus_hourly_logs_error_message: 'Failed to update Mbus Hourly logs for the project',
  sidebar_mbus_reporting: 'Mbus Reporting',
  mbus_reporting_table_device: 'Device',
  mbus_reporting_table_project: 'Project',
  mbus_reporting_table_created_at: 'Created At',
  mbus_reporting_table_viewed_by: 'Viewed By',
  mbus_reporting_table_viewed_at: 'Viewed At',
  mbus_reporting_table_firmware: 'Firmware',
  mbus_reporting_table_no_record_found: 'No Records Found',
  mbus_reporting_table_all_filter: 'All',
  mbus_reporting_table_read_filter: 'Read',
  mbus_reporting_table_unread_filter: 'Unread',
  mbus_reporting_table_row_per_page: 'Rows per page',
  mbus_reporting_table_title: 'Mbus Reporting',
  mbus_reporting_anomaly_table_title: 'Mbus Anomalies',
  mbus_reporting_anomaly_table_difference: 'Difference',
  mbus_reporting_anomaly_table_detection: 'Detection',
  mbus_reporting_anomaly_table_mbus_meter_type: 'Meter',
  mbus_reporting_anomaly_table_mbus_serial_number: 'Mbus Serial Number',
  mbus_reporting_anomaly_table_mbus_old_value: 'Old Value',
  mbus_reporting_anomaly_table_mbus_register: 'Register',
  mbus_reporting_anomaly_table_mbus_reason: 'Reason',
  mbus_reporting_anomaly_table_mbus_fail_reason: 'Greater than',
  mbus_reporting_anomaly_table_mbus_suspicious_reason: 'Lies in between',
  mbus_reporting_anomaly_table_fail: 'Fail',
  mbus_reporting_anomaly_table_suspicious: 'Suspicious',
  mbus_reporting_table_bulk_view_reports_success_message: 'Mbus Reporting Messages are successfully viewed',
  mbus_reporting_table_bulk_view_reports_error_message: 'Please select Mbus Reporting records to proceed',
  mbus_reporting_table_bulk_view_button: 'Bulk View',
  mbus_reporting_table_view_button: 'View',
  mbus_reporting_table_filters_project_field: 'Project',
  mbus_reporting_table_filters_device_name_field: 'Device',
  mbus_reporting_table_filters_start_date: 'Start Date',
  mbus_reporting_table_filters_end_date: 'End Date',
  mbus_reporting_table_filters_firmware: 'Firmware',
  mbus_reporting_table_filters_mbus_serial_number: 'Mbus Serial Number',
  mbus_reporting_table_filters_viewed_by: 'Viewed By',
  business_situation_measured_values_209: 'Outdoor Temperature',
  custom_popover_209_short_description: 'Outdoor Temperature',
  custom_popover_209_long_description: 'Outdoor Temperature',
  appliance_types_set_registers_table_dashboard: 'Dashboard',
  appliance_types_set_registers_table_dashboard_error_message: 'Minimum 0 and Maximum 6 Dashboard Registers are allowed for device dashboard',
  sidebar_missing_hourly_reporting: 'Mbus Missing Data Report',
  missing_hourly_reporting_table_title: 'Mbus Hourly Missing Data Reporting',
  mbus_hourly_missing_reporting_table_filters_project_field: 'Project',
  mbus_hourly_missing_reporting_table_filters_device_name_field: 'Device',
  mbus_hourly_missing_reporting_table_filters_firmware_field: 'Firmware',
  mbus_hourly_missing_reporting_table_last_seen: 'Mbus Message At',
  mbus_hourly_missing_reporting_table_created_at: 'Created At',
  mbus_hourly_missing_reporting_table_device: 'Device',
  mbus_hourly_missing_reporting_table_project: 'Project',
  mbus_hourly_missing_reporting_table_firmware: 'Firmware',
  mbus_hourly_missing_reporting_table_no_record_found: 'No Records Found',
  mbus_hourly_missing_reporting_table_row_per_page: 'Rows per page',
  device_monitoring_device_frequency_history_counters_title: 'Device Anomalies Frequency',
  device_monitoring_device_frequency_history_counters_error: 'No data found',
  device_monitoring_device_frequency_history_counters_no_graph_data_error: 'The piechart is unavailable',
  device_monitoring_device_frequency_history_counters_loading_data: 'Loading ...',
  device_monitoring_project_frequency_history_counters_title: 'Project Anomalies Frequency',
  device_monitoring_project_frequency_history_counters_error: 'No data found',
  device_monitoring_project_frequency_history_counters_no_graph_data_error: 'The piechart is unavailable',
  device_monitoring_project_frequency_history_counters_loading_data: 'Loading ...',
  device_monitoring_firmware_anomalies_history_title: 'Firmware Anomalies Frequency',
  device_monitoring_device_firmware_anomalies_history_graph_loading_data: 'The barchart is unavailable',
  device_monitoring_device_firmware_anomalies_history_no_graph_data_error: 'Loading ...',
  devices_device_id: 'Device Id',
  device_monitoring_device_frequency_details_table_title: 'Device Anomalies Frequency',
  device_monitoring_device_frequency_details_table_firmware: 'Firmware',
  device_monitoring_device_frequency_details_table_device: 'Device',
  device_monitoring_device_frequency_details_table_project: 'Project',
  device_monitoring_device_frequency_details_table_saved_at: 'Date',
  device_monitoring_device_frequency_details_table_startup_messages_count: 'Start up messages count',
  device_monitoring_project_frequency_details_table_title: 'Project Anomalies Frequency',
  device_monitoring_project_frequency_details_table_project: 'Project',
  device_monitoring_project_frequency_details_table_devices_count: 'Devices Count',
  device_monitoring_project_frequency_details_table_startup_messages_count: 'Start up messages count',
  device_monitoring_firmware_frequency_details_table_title: 'Firmware Anomalies Frequency',
  device_monitoring_firmware_frequency_details_table_firmware: 'Firmware',
  device_monitoring_firmware_frequency_details_table_startup_messages_count: 'Start up messages count',
  client_single_users_list_reset_and_send_email_button: 'Reset Password',
  user_reset_password_form_success: 'User password is updated successfully',
  users_reset_password_modal_title: 'Reset Password',
  users_reset_password_modal_subtitle: 'Do you want to reset password for this user and send email?',
  users_reset_password_modal_cancel_button: 'Close',
  users_reset_password_modal_reset_password_button: 'Reset and Send Email',
  reset_password_subject:
    'Fortes Data Management - Reset Password',
  clients_form_is_2fa_enabled_field:
           'Enable Two-Factor Authentication (2FA)',
  service_clients_form_is_2fa_enabled_field:
           'Enable Two-Factor Authentication (2FA)',
  two_factor_authentication_qr_verified_title:
           'Two-Factor Authentication (2FA)',
  two_factor_authentication_qr_unverified_title:
           'Setup Two-Factor Authentication (2FA)',
  two_factor_authentication_permission_error: '',
  two_factor_authentication_description_step_1:
           'Install one of the following applications on your mobile:',
  two_factor_authentication_description_step_2:
           'Open the application and scan the QR Code:',
  two_factor_authentication_description_step_2_rescan:
           'Regenerate QR Code',
  two_factor_authentication_description_step_3:
           'Enter the one-time code provided by the application and click Submit to finish the setup.',
  two_factor_authentication_form_otp_field: 'OTP Code',
  two_factor_authentication_form_otp_characters_limit_error:
           'OTP must be 6 characters',
  two_factor_authentication_form_otp_required_error: 'OTP is required',
  two_factor_authentication_form_incorrect_otp_error:
           'Invalid OTP, please retry again with a new OTP',
  two_factor_authentication_form_success:
           'OTP is successfully authenticated',
  two_factor_authentication_submit_button: 'Submit',
  client_single_users_list_enable_2fa_qr_code_button:
           'Enable Two-Factor Authentication (2FA) QR Code',
  service_client_single_users_list_enable_2fa_qr_code_button:
           'Enable Two-Factor Authentication (2FA) QR Code',
  users_enable_2fa_qr_code_modal_title:
           'Enable Two-Factor Authentication (2FA) QR Code',
  users_enable_2fa_qr_code_modal_subtitle:
           'Are you sure you want to enable Two-Factor Authentication (2FA) QR Code for this user?',
  users_enable_2fa_qr_code_modal_cancel_button: 'Cancel',
  users_enable_2fa_qr_code_modal_enable_2fa_qr_code_button:
           'Enable Two-Factor Authentication (2FA) QR Code',
  client_single_users_list_enable_2fa_qr_code_success_message:
           'Two-Factor Authentication (2FA) QR Code is successfully enabled',
  client_single_2fa_label: 'Two-Factor Authentication (2FA)',
  enabled_2fa_label: 'Enabled',
  disabled_2fa_label: 'Disabled',
  service_client_single_2fa_label: 'Two-Factor Authentication (2FA)',
  client_single_users_table_last_access: 'Last Seen',
  service_client_single_users_table_last_access: 'Last Seen',
  projects_form_header: 'Project Information',
  projects_commissioning_form_header: 'Project Commissioning',
  projects_commissioning_form_export_button: 'Export Devices',
  projects_commissioning_form_export_file_name:
           'Project Commissioning Export',
  projects_commissioning_form_import_button: 'Import Devices',
  projects_commissioning_form_import_message:
           '<strong>Steps to Import Devices</strong> <ol> <li>Download the Project Commissioning File Template from green Export Devices Button.</li> <li>Update the file and don\'t change the header column names, in case they\'re changed. The file import will fail.</li> <li>Import the file and changes will be updated.</li> </ol>',
  projects_commissioning_form_import_success_message:
           'Devices data is successfully commissioned',
  commissoning_device_stepper_step_1_scan_qr_serial_number_title:
           'Scan QR serial number ',
  commissoning_device_stepper_step_2_validate_project_setting_title:
           'Validate Modbus and M-Bus',
  commissoning_device_stepper_step_3_update_address_information_title:
           'Update Address',
  commissoning_device_stepper_step_4_hiu_commissioning_title:
           'HIU Commissioning',
  commissoning_device_stepper_step_5_preview_and_submit_title:
           'Preview and Submit',
  commissoning_form_dont_have_serial_number_checkbox:
           "Don't have Serial QR Code?",
  commissoning_form_back_button: 'Previous',
  commissoning_form_next_button: 'Next',
  commissoning_form_finish_button: 'Finish',
  commissoning_form_device_serial_is_missing_error:
           'Serial Number is required',
  commissoning_form_modbus_mbus_validation_error:
           'Validation failed, do you want to proceed anyway?',
  commissoning_form_stepper_step_2_validation_project_setting_title:
           'Project Settings',
  commissoning_form_stepper_step_2_validation_current_setting_title:
           'Device Settings',
  commissoning_form_stepper_step_2_validation_validate_to_view:
           'Validate to view details',
  commissoning_form_stepper_step_2_validation_modbus_title: 'Modbus',
  commissoning_form_stepper_step_2_validation_mbus_title: 'M-Bus',
  commissoning_form_stepper_step_2_validation_modbus_connected_title: 'Connected',
  commissoning_form_stepper_step_2_validation_modbus_not_connected_title: 'Not Connected',
  commissoning_form_stepper_step_2_validation_mbus_meters_title: 'meter(s)',
  project_single_project_setting_modbus_and_mbus_validation_form_title: 'Modbus and Mbus Setting',
  commissoning_form_stepper_step_2_validation_is_modbus_connected: 'Is Modbus Connected',
  commissoning_form_stepper_step_2_validation_mbus_meters_select_field: 'M-Bus Meters',
  commissoning_form_stepper_step_2_validation_mbus_meters_select_option_field: 'Select M-Bus Meters',
  commissoning_form_stepper_step_2_validation_mbus_meters_validate_button: 'Validate',
  commissoning_form_upload_file_type_error: 'Only XLSX files are allowed.',
  commissoning_form_upload_file_invalid_column_names_error: 'Invalid column ##COLUMNS##, please don\'t change the column names',
  commissoning_form_upload_file_size_error: 'File size should be less than 5 MB.',
  commissoning_form_uploading_text: 'Uploading...',
  project_single_project_setting_mbus_reporting_form_title: 'Mbus Reporting Setting',
  project_single_project_setting_mbus_hourly_missing_data_reporting_value_field: 'Mbus Hourly Missing Data Reporting',
  project_single_project_setting_mbus_anomaly_reporting_value_field: 'Mbus Anomaly Reporting',
  project_single_project_setting_mbus_reporting_form_button: 'Update',
  project_single_project_setting_mbus_reporting_success_message:
           'Mbus Reporting data is successfully set for the project',
  project_single_project_setting_mbus_reporting_error_message:
           'Failed to update Mbus Reporting data for the project',
  commissoning_form_upload_file_incorrect_serial_number_error: 'Incorrect Serial Number(s) found for the project commissioning',
  commissoning_form_upload_file_incorrect_devices_data_error: 'Incorrect Device(s) data found for the project commissioning',
  commissoning_form_upload_file_empty_sheet_error: 'Project Commissioning file is empty',
  commissoning_form_upload_success_message: 'Project Commissioning is successfully completed',
  commissioning_validation_failed_subject: 'Fortes Energy - Device Commissioning Wizard Validation',
  commissoning_form_stepper_step_2_validation_mbus_meters_loading_title: 'Mbus meters check takes upto 30 - 60 seconds',
  commissoning_form_stepper_step_2_validation_mbus_meters_retries_label: 'Retries',
  commissoning_form_stepper_step_2_validation_mbus_meters_retries_button: 'Retry',
  error_docs_table_folder_name: 'Folder Name',
  error_docs_table_file_name: 'File Name',
  error_docs_no_record_found: 'No Records Found',
  error_docs_title: 'Document Errors',
  error_docs_filter_search: 'Search',
  error_docs_total_records: 'Total Records',
  error_docs_link: 'Document Errors',
  docs_third_tab_montage_instructie: 'Montage instructie',
  device_single_documentation_instructie_pomp_button: 'Instructie Pomp',
};
